import React from 'react'
import SideBar from './pages/admin/navbar/SideBar'
import Topbar from './pages/admin/navbar/TopBar'


const AdminLayout = ({ title, children }) => {
    return (
        <div className="container-fluid   bg-light-grey">
            <div className="row ">

                <div className="col-md-2 col-3 px-0 ">
                    <SideBar />
                </div>

                <div className="col-md-10 content-body px-0 col-9">

                    <Topbar title={title}></Topbar>


                    <div className="d-flex p-3 flex-column">
                        {children}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AdminLayout
