import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'react-simple-snackbar';
import KentBanner from '../../assets/kent-fan-banner.svg';
import fanImg from '../../assets/fan_img.svg';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../util/EnvHelper';
import { toPrefixedLocalePrice } from '../../util/Util';
import { addToCart, getCartCount, getCartlist,removeCartItem,getProductList, getPartnerAnnouncement,} from '../../api/API';
import { CustomOptions } from '../../pages/user/login/Login';
import { getUser , getAnnouncementStatus,setAnnoucementFlag} from '../../util/LocalStorageUtil'
import { Link } from 'react-router-dom';
import { CartContext } from "../../CartContext";
import { useContext } from 'react';
import './index.scss'
const Index = ({ productList }) => {
    const navigate = useNavigate();
    const [openSnackbar] = useSnackbar(CustomOptions)
    const [isLoading, setIsLoading] = React.useState(true);
    const [state, setState] = React.useState({});
    const [isIncrement, setIsIncrement] = React.useState(false);
    const [isDecrement, setIsDecrement] = React.useState(false);
    const [loadedTarget, setLoadedTarget] = React.useState(null);
    const [count, setCount] = useState(null);
    const [cartList, setCartlist] = useState([]);
    const [inCart, setInCart] = React.useState(false);
    const [user,setUser] = useState([])
    const { cartCount, getCount } = useContext(CartContext)
    const [announcement,setAnnouncement] = useState([])
    const [anFlag,setAnFlag] = useState(getAnnouncementStatus())

    useEffect(() => {
        getCount()
        getProdList()
        getCartDetails()
        setUser(getUser())
        getAnnouncementDesc()
        // console.log(anFlag.anId, anFlag.flag);
    }, [cartCount],[anFlag])

    const getProdList = async () => {
        getProductList()
            .then(data => {
                setIsLoading(false)
            }).catch(e => {
                setIsLoading(false)
                openSnackbar(e)
            })
    };
    
    const getAnnouncementDesc = () => {
        getPartnerAnnouncement()
        .then(data => {
            setAnnouncement(data)
        }).catch(e => {
        })
    }

    const setValueIncrement =  (event, skuRid) => {
        setLoadedTarget(event.target.name)
        setIsIncrement(true)
        setState({ ...state, [event.target.name]: Number(event.target.value) + Number(user?.minQty) });
        let cartIncValue = Number(event.target.value) + Number(user?.minQty)
        addCart(cartIncValue, skuRid, true, event.target.name)
        // getCartCountData();
        
    };

    const setValueDecrement = async (event, skuRid) => {
        if (event.target.value > 4) {
            setLoadedTarget(event.target.name)
            setIsDecrement(true)
            setState({ ...state, [event.target.name]: Number(event.target.value) - Number(user?.minQty) });
            let cartDecValue = Number(event.target.value) - Number(user?.minQty);
            addCart(cartDecValue, skuRid, false, event.target.name)
        }
        else{
            removeFromCart(skuRid)
        }
    };
    
    const addCart = (valueCount, skuID, isCheckIncrement, targetName) => {
        const result = productList.find(sku => (skuID === sku.skuRid))
        // console.log(valueCount)
        if (valueCount === 0) {
            openSnackbar('Please select quantity!')
            setLoadedTarget(targetName)
            setIsIncrement(false)
            return
        }
        else if(valueCount === Number(user?.minQty) && isCheckIncrement){
            openSnackbar('Added to cart')
        }
        if(result.stock < valueCount) {
            // console.log(result.stock)
            openSnackbar("Required quantity not in stock. Please try for lower quantity.")
            return
        }
        else{
            addToCart({ skuRid: skuID, qty: valueCount })
            .then((data) => {
                if (isCheckIncrement == 1) {
                    setLoadedTarget(targetName)
                    setIsIncrement(false)
                    getCount()
                }
                else {
                    setLoadedTarget(targetName)
                    setIsDecrement(false)
                }
                getCartDetails()


            })
            .catch(e => {
                if (isCheckIncrement == 1) {
                    setLoadedTarget(targetName)
                    setIsIncrement(false)
                }
                else {
                    setLoadedTarget(targetName)
                    setIsDecrement(false)
                }
                openSnackbar(e)
            })
        }
    }
    
    const viewMoreDetails = (skuRid) => {
        navigate(`/product/${skuRid}`)
    }

    const getCartDetails = async () => {
        getCartlist()
            .then(data => {
                setCartlist(data);
                setIsLoading(false)
                
            }).catch(e => {
                setIsLoading(false)
                openSnackbar(e)
            })
    };

    const handleCloseAnnouncement = () => {
        setAnnoucementFlag({anId:anFlag.anId,flag:1})
        setAnFlag(getAnnouncementStatus())
      }
      

    const removeFromCart = (skuRid) => {
        removeCartItem({ skuRid: skuRid })
            .then(data => {
                openSnackbar(data)
                getCartDetails()
                getCount()
            })
            .catch(e => {
                openSnackbar(e)
            })
    }

    const CartButton = (skuRid) => {
        setInCart(true)
        const result = cartList.find(sku => (skuRid.skuRid === sku.skuRid))
            if(result){
                return(
                    <>
                        <div className="btn-none col-md-4 btn btn-box w-100 p-0 d-flex flex-row justify-content-between">
                            <button className="btn btn-success mx-0 my-0 py-2  px-3 fs-x-large-med text-white" name={'btn' + skuRid.skuRid} value={result.qty}
                                onClick={(e) => { setValueDecrement(e, skuRid.skuRid) }}>-</button>
                            <p className="my-auto fs-normal-reg text-green" >{result.qty}</p>
                            <button className="btn btn-success mx-0 my-0 py-2 px-3 fs-x-large-med text-white" name={'btn' + skuRid.skuRid} value={ result.qty}
                                onClick={(e) => { setValueIncrement(e,skuRid.skuRid) }}>+</button>
                        </div>
                    </>
                )
            }
            else{
                return(
                    <>
                        <button onClick={(e) => {addCart(Number(user?.minQty),skuRid.skuRid,true,e.target.name)}} className="btn col-md-4 btn-box btn-outline-success fs-x-large-med text-green w-100 py-lg-2">Add</button>
                    </>
                )
            }
        }

    return (
        <div className="col-md-12 justify-content-center my-md-200 z-ind-0 mb-md-5" >
            <div className="row justify-content-center ">
                <div className="col-md-8 d-lg-block d-md-block d-none">
                    <div className="mb-3">
                        <div className="d-flex flex-row ">
                            <Link className="d-flex flex-row  a-nonlink" to="/landing">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#5CA772" className="bi bi-chevron-left mt-1" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                </svg>
                                <p className="fs-large-med btn-link text-green ms-2">Home</p>
                            </Link>
                            <p className="fs-large-light text-dark ms-2">/ Browse Products</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    {/* <div className="img-text">
                        <img src={KentBanner} alt="KentBanner" className="w-100" />
                    </div> */}
                    {(announcement!== null || (announcement.description !== null && announcement.description !== "")) && anFlag?.flag!==1 ? 
                        <div className="card h-100 rounded-card p-4 mb-3 mt-3">
                          
                            <div className='col-md-12 d-flex flex-row justify-content-between'>
                                <div className='col-md-8 d-flex flex-row'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-megaphone me-3 text-green" viewBox="0 0 16 16">
                                        <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49a68.14 68.14 0 0 0-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 74.663 74.663 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199V2.5zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0zm-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233c.18.01.359.022.537.036 2.568.189 5.093.744 7.463 1.993V3.85zm-9 6.215v-4.13a95.09 95.09 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A60.49 60.49 0 0 1 4 10.065zm-.657.975 1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68.019 68.019 0 0 0-1.722-.082z"/>
                                    </svg>
                                    <p className='fs-large-semibold text-green'>Announcements</p>
                                </div>
                                <svg   onClick={() => handleCloseAnnouncement()}xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-x-circle text-danger pointer" viewBox="0 0 16 16">
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>  
                            </div>
                            <p className='fs-normal-reg text-dark'>{announcement.description}</p>
                        </div> 
                        : null
            }
                    
                </div>
                <div className="col-md-8">
                    <div className="row  justify-content-left mt-3 mt-md-5">
                        {productList !== null ?
                            productList.map((item, index) => (
                                <div className="col-md-3 mb-3 col-6 pointer" key={index} >
                                    <div className="card h-100 rounded-card">
                                        <div className="text-center mt-4 mt-md-5 mx-3" onClick={() => { viewMoreDetails(item.skuRid) }}>
                                            <img src={BASE_URL + "/" + item.imageUrl}
                                                // onError={e => { e.currentTarget.src = "error.png"; }}
                                                className="img-fluid product-img w-100"  alt={item.title}/>
                                        </div>
                                        <div class="card-body p-md-3 p-2 mt-2">
                                                {/* <button className="btn py-1 rounded-btn fs-x-small-reg">
                                                    {item.prodName}
                                                </button> */}
                                            <p className="fs-normal-reg mt-1 mt-md-2 mb-0">
                                                {item.prodName}
                                            </p>
                                            <p className="fs-normal-reg mt-md-2 mb-1">
                                                ({item.skuName})
                                            </p>
                                            <p className="fs-large-semibold mb-0 mt-1">{toPrefixedLocalePrice(item.basePrice)}</p>
                                            <p className='text-grey fs-normal-med mt-0 mb-2'>excl. of GST</p>
                                            {/* <button  onClick={() => {addCart(item.skuRid) }} className="btn btn-box btn-outline-success fs-normal-med text-green w-100 p-1">Add</button> */}
                                            {item.stock!==0 && item.stock >= user?.minQty ?
                                                <CartButton skuRid={item.skuRid}/>
                                                : <p className=' mt-3 text-center fs-normal-reg text-danger'>Out of Stock</p>
                                            }
                                        </div>

                                    </div>
                                </div>
                            )) :
                            <div className="col-md-12 text-center">
                                <h1 className="fs-large-regular"><i>No records</i></h1>
                            </div>}
                    </div>

                    
                </div>
                <div className='col-md-12 d-none d-lg-block mt-5'></div>
                <div className='col-md-12 d-none d-lg-block mt-5'></div>
                    
                    {cartCount!==0 && cartCount!==null ?
                        <div className="d-sm-block mb-0 ms-0 d-md-flex d-lg-flex justify-content-center sticky-div w-100">
                                <div className="col-10 col-md-6 ms-3 mb-2 text-left">
                                    <Link to='/cart' className='a-nonlink'>
                                        <button className="btn w-100 btn-box bg-green mx-auto">
                                            <span className="fs-normal-reg text-light">Place order</span>
                                        </button>
                                    </Link>
                                </div>
                        </div> 
                    :null}
            </div>
        </div>

    )
}
export default Index