import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";


const FeedbackModal = () => {
    const navigate = useNavigate()
    const handleFeeback = () => {
        navigate('/landing')
    }
    return (
        <div className="modal fade custom-modal" id="feedbackModal" tabIndex="-1" aria-labelledby="FilterModal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">

                    <div className="modal-body px-0">
                        <div className="container  p-2rem">
                            <div className="row">

                            <div className="col-md-12 my-auto mb-2">
                                <p className="my-auto text-center float-start fs-large-regular my-3">Feedback has been successfully sent, we will get back to you shortly!</p>
                            </div>
                                
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer justify-content-center pb-0">
                        <button onClick={()=>{handleFeeback()}}className="btn btn-success br-21 fs-large-semibold text-light mb-3 px-5 py-2" data-bs-dismiss="modal">Okay</button>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default FeedbackModal