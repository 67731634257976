import React, { useEffect, useState } from 'react'
import LockIcon from "@mui/icons-material/LockOpenOutlined";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@material-ui/core/Button";
import './profile.scss';
import UserLayout from '../../../UserLayout';
import { Link } from 'react-router-dom';

const ChangePassword = () => {

    const [openSnackbar, setOpenSnackbar] = React.useState(false);

    const closeSnackBar = (event, reason) => {
        if ("clickaway" == reason) return;
        setOpenSnackbar(false);
    };

    const openSnackBar = () => {
        // setOpenSnackbar(true);
    };


    const Msg = () => {
        return (
            <div className="ms-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2-circle text-dark" viewBox="0 0 16 16">
                    <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                    <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                </svg>
                <span className=" fs-normal-reg text-dark ms-2" >Saved changes</span>
            </div>
        )
    }

    return (
        <UserLayout>
            <div className="container-fluid   align-self-center">
                <div className="row justify-content-center">
                    <div className="container my-md-200 ">
                        {/* <div className="row text-center">
                        <a href="/change-password-success" className="a-nonlink">
                            <span className=" fs-normal-reg text-green" >Change Password Page</span></a>
                    </div> */}
                        <div className="row justify-content-center">
                            <div className="col-md-8">

                                <div className="col-md-7 card z-ind-0  bg-transparent b-none " id="scard">
                                    <div className="card-body px-0">

                                    <div className="mb-3 d-none d-lg-block">
                                        <div className="d-flex flex-row ">
                                            <Link className="d-flex flex-row  a-nonlink" to="/profile">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#5CA772" className="bi bi-chevron-left mt-1" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                                </svg>
                                                <p className="fs-large-med btn-link text-green ms-2">Profile</p>
                                            </Link>
                                                <p className="fs-large-light text-dark ms-2">/ Edit Profile</p>
                                        </div>
                                    </div>
                                        <div className="accordion accordion-flush pwd-accordion zi-0" id="accordionFlushExample">
                                            <div className="accordion-item bg-transparent">
                                                <h2 className="accordion-header fs-l-large-regular" id="flush-headingOne">
                                                    <button className="accordion-button collapsed  px-0 bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" id="heading" aria-expanded="false" aria-controls="flush-collapseOne">
                                                        <LockIcon />  &nbsp;&nbsp;&nbsp;Change Password
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body px-0">

                                                        <input
                                                            className="form-control w-md-50 form-control-sm fs-large-regular py-2 mb-md-4 mb-3 login-input"
                                                            type="password"
                                                            placeholder="Old password"
                                                            aria-label=".form-control-sm example"
                                                        />
                                                        <input
                                                            className="form-control w-md-50 form-control-sm fs-large-regular  py-2 mb-md-4 mb-3 login-input"
                                                            type="password"
                                                            placeholder="New Password"
                                                            aria-label=".form-control-sm example"
                                                        />
                                                        <input
                                                            className="form-control w-md-50 form-control-sm fs-large-regular  py-2 mb-md-4 mb-3 login-input"
                                                            type="password"
                                                            placeholder="Re-type new password"
                                                            aria-label=".form-control-sm example"
                                                        />
                                                        <div className="row justify-content-left">
                                                            <div className="col-sm-12  pe-md-0">
                                                                <a type="button" onClick={openSnackBar} className="btn btn-success w-100 fs-large-semibold text-white" id="button">
                                                                    Save Changes
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cart-snackbar">
                        <Snackbar
                            anchorOrigin={{
                                horizontal: "center",
                                vertical: "bottom",
                            }}
                            open={openSnackbar}

                            message={<Msg />}
                            onClose={closeSnackBar}
                            action={
                                <React.Fragment>

                                    <Button
                                        aria-label="close"
                                        sx={{ p: 0.5 }}
                                        onClick={closeSnackBar}

                                    >
                                        <CloseIcon />
                                    </Button>
                                </React.Fragment>
                            }
                        />
                    </div>

                </div>
            </div>
        </UserLayout>
    )
}
export default ChangePassword