import React, { useEffect } from "react";
import { Dropdown, } from 'bootstrap';
import { Link } from "react-router-dom";
import ProfileLogo from '../../../assets/profile.svg';
import { color } from "@mui/system";
import FormControlLabel from '@mui/material/FormControlLabel';
import { Switch, Checkbox } from '@mui/material';
;
const OrderEditModal = () => {
    const [state, setState] = React.useState({
        gilad: true,

    });

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };
    useEffect(() => {
        window.onpopstate = e => {
            window.location.reload()
        }
    })
    return (
        <div className="modal fade custom-modal" id="modalEditOrder" tabIndex="-1" aria-labelledby="vendorEditAddModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg max-w-600 modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body px-0">
                        <div className="container  p-2rem">
                            <div className="row">
                                <div className="col-md-12 my-auto mb-2">
                                    <p className="my-auto  float-start fs-large-med">
                                        01
                                    </p>

                                    <button type="button" className=" float-end px-0 my-auto custom-btn" data-bs-dismiss="modal" aria-label="Close">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-circle float-end text-primary" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="col-md-6">
                                    <label className="fs-normal-reg mb-2">Order number</label>
                                    <input type="text" className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4" value="12524" /></div>
                                <div className="col-md-6">
                                    <label className="fs-normal-reg mb-2">Order date</label>
                                    <input type="text" className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4" value="28/10/2012" /></div>
                                <div className="col-md-6">
                                    <label className="fs-normal-reg mb-2">Total price </label>
                                    <input type="text" className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4" value="40,000" /></div>
                                <div className="col-md-6">
                                    <label className="fs-normal-reg mb-2 ">Order status</label>
                                    <select className="form-select custom-select mb-3 fs-large-med" aria-label="Default select example">
                                        <option selected>Done</option>
                                        <option value="1">Progress</option>
                                        <option value="2">Abcd</option>
                                        <option value="3">Efgh</option>
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <label className="fs-normal-reg mb-2">Tracking ID</label>
                                    <input type="text" className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4" value="-" /></div>




                            </div>
                        </div>
                        <div className="modal-footer pb-0">
                            <Link to="/order-list" type="button" className="btn btn-secondary fs-large-med me-3" data-bs-dismiss="modal">Cancel</Link>
                            <Link to="/order-list" className="btn btn-primary br-21 fs-large-semibold text-light px-5 py-2" data-bs-dismiss="modal">Save changes</Link>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )



}
export default OrderEditModal