import React , {useEffect, useState} from 'react'
import UserLayout from '../../../UserLayout'
import './placeorder.scss';
import SearchMenu from '../../../components/search-dropdown/search-menu';
import { getProductList } from '../../../api/API';
import { useSnackbar } from 'react-simple-snackbar'
import { CustomOptions } from '../login/Login';
import {toPrefixedLocalePrice } from '../../../util/Util';
import { getUser,getAnnouncementStatus,setAnnoucementFlag} from '../../../util/LocalStorageUtil';
import { addToCart, getCartlist,removeCartItem,getPartnerAnnouncement } from '../../../api/API';
import { Link,useNavigate,useSearchParams } from 'react-router-dom';
import { Modal } from 'bootstrap'
import FilterModal from './FilterModal'
import { RiNurseFill } from 'react-icons/ri';
import { useLocation } from 'react-router';
import { CartContext } from "../../../CartContext";
import { useContext } from 'react';



const PlaceOrder = () => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [data, setData] = React.useState({});
    const navigate = useNavigate();
    const [isIncrement, setIsIncrement] = React.useState(false);
    const [isDecrement, setIsDecrement] = React.useState(false);
    const [loadedTarget, setLoadedTarget] = React.useState(null);
    const [productList, setProductList] = useState([]);
    const [openSnackbar] = useSnackbar(CustomOptions)
    const [cartList, setCartlist] = useState([]);
    const [user, setUser] = useState()
    const [sortItem,setSortItem] = useState(true)
    const { state } = useLocation();
    const location = useLocation();
    const [searchParams] = useSearchParams()
    const params = Object.fromEntries([...searchParams]);
    const [searchQuery, setSearchQuery] = useState(params?.q ?? '')
    const [announcement,setAnnouncement] = useState([])
    const [anFlag,setAnFlag] = useState(getAnnouncementStatus())


    const { cartCount, getCount } = useContext(CartContext)

    useEffect(() => {
        getCount()
        getCartDetails()
        setUser(getUser())
        getAnnouncementDesc()
        // console.log(location)
        if(searchQuery){
            getProductList({skuCode: searchQuery})
            .then(data => {
                // console.log(data)
                setProductList(data)
            }).catch(e=>{
                openSnackbar(e)
            })
        }
        else if(state){
            setProductList(state)
        }
        else{
            getProdList()
        }
    }, [state],[productList],[cartCount],[anFlag])

    const getAnnouncementDesc = () => {
        getPartnerAnnouncement()
        .then(data => {
            setAnnouncement(data)
        }).catch(e => {
        })
    }

    const handleCloseAnnouncement = () => {
        setAnnoucementFlag({anId:anFlag.anId,flag:1})
        setAnFlag(getAnnouncementStatus())
      }

    const getProdList = async () => {
        getProductList()
            .then(data => {
                setIsLoading(false)
                setProductList(data);
                // console.log(data)
            }).catch(e => {
                setIsLoading(false)
                openSnackbar(e)
            })
    };
    
    const setValueIncrement =  (event, skuRid) => {
            setLoadedTarget(event.target.name)
            setIsIncrement(true)
            setData({ ...data, [event.target.name]: Number(event.target.value) + Number(user?.minQty) });
            let cartIncValue = Number(event.target.value) + Number(user?.minQty)
            addCart(cartIncValue, skuRid, true, event.target.name)
            getCount()
    };

    const setValueDecrement = async (event, skuRid) => {
        if (event.target.value > 4) {
            setLoadedTarget(event.target.name)
            setIsDecrement(true)
            setData({ ...data, [event.target.name]: Number(event.target.value) - Number(user?.minQty) });
            let cartDecValue = Number(event.target.value) - Number(user?.minQty);
            addCart(cartDecValue, skuRid, false, event.target.name)
        }
    };
    
    const addCart = (valueCount, skuID, isCheckIncrement, targetName) => {
        const result = productList.find(sku => (skuID === sku.skuRid))
        // console.log(valueCount)
        if (valueCount === 0) {
            openSnackbar('Please select quantity!')
            setLoadedTarget(targetName)
            setIsIncrement(false)
            return
        }
        else if(valueCount === Number(user?.minQty) && isCheckIncrement){
            openSnackbar('Added to cart')
        }
        if(result.stock < valueCount && isCheckIncrement) {
            openSnackbar("Required quantity not in stock. Please try for lower quantity.")
            return
        }
        else{
        addToCart({ skuRid: skuID, qty: valueCount })
            .then((data) => {
                if (isCheckIncrement == 1) {
                    setLoadedTarget(targetName)
                    setIsIncrement(false)
                }
                else {
                    setLoadedTarget(targetName)
                    setIsDecrement(false)
                }
                getCartDetails()
                getCount()


            })
            .catch(e => {
                if (isCheckIncrement == 1) {
                    setLoadedTarget(targetName)
                    setIsIncrement(false)
                }
                else {
                    setLoadedTarget(targetName)
                    setIsDecrement(false)
                }
                openSnackbar(e)
            })
    }
}
    const filterModal = async () => {
        const openFilterModal = new Modal(document.querySelector('#filterModal'), {
            keyboard: false
        })
        openFilterModal.show()
    }

    const getCartDetails = async () => {
        getCartlist()
            .then(data => {
                setCartlist(data);
                setIsLoading(false)
            }).catch(e => {
                setIsLoading(false)
                openSnackbar(e)
            })
    };

    const removeFromCart = (skuRid) => {
        removeCartItem({ skuRid: skuRid })
            .then(data => {
                openSnackbar("Item Removed from Cart")
                getCartDetails()
                getCount()
            })
            .catch(e => {
                openSnackbar(e)
            })
    }

    const sort = (sort) =>{
        setSortItem(sort)
        getProductList({sortPrice:sort})
        .then(data => {
            setProductList(data)
        })
    }

    const clearFilter = () => {
        navigate("/place-order")
        window.location.reload()
    }

    const CartButton = (skuRid) => {
        const result = cartList.find(sku => (skuRid.skuRid === sku.skuRid))
            if(result){
                return(
                    <>
                    
                        <div className="btn-none col-3 col-md-8 btn btn-box me-3 mb-lg-3 px-0 py-0 d-flex flex-row justify-content-between">
                             <button className=" btn  btn-success mx-0 my-0 py-1 px-2 py-lg-2 px-lg-3 fs-large-reg text-white" name={'btn' + skuRid.skuRid} value={result.qty}
                                onClick={(e) => { setValueDecrement(e, skuRid.skuRid) }}>-</button>
                            <p className=" mx-2 my-auto fs-large-reg text-green" >{result.qty}</p>
                              <button className="btn  btn-success mx-0 my-0 py-1 py-lg-2 px-lg-3 px-2 fs-large-reg text-white" name={'btn' + skuRid.skuRid} value={result.qty}
                                onClick={(e) => { setValueIncrement(e,skuRid.skuRid) }}>+</button>
                        </div>
                    
                    </>
                )
            }
            else{
                return(
                    <>
                    
                        <button onClick={(e) => {addCart(Number(user?.minQty),skuRid.skuRid,true,e.target.name)}} className="btn col-3 col-md-8 me-3 btn-box btn-outline-success fs-x-large-med text-green mx-lg-5 mb-lg-3 py-1 px-2 py-lg-2 me-2">Add</button>

                    </>
                )
            }
        }
    
        const ClearButton = (skuRid) => {
            const result = cartList.find(sku => (skuRid.skuRid === sku.skuRid))
            if(result){
                return(
                <>
                    <button onClick={() => {removeFromCart(skuRid.skuRid)}} className='btn d-block d-md-none d-lg-none btn-link fs-normal-reg'>Clear</button>
                    <button onClick={() => {removeFromCart(skuRid.skuRid)}} className='btn d-lg-block d-none btn-link fs-large-med mb-lg-1 text-end p-0'>Clear</button>
                </>
                )
            }
            else{
                return(
                    <>
                         <div className='row col-2 mb-lg-1 col-md-2'> &nbsp;</div> 
                    </>
                )
            }
        }
    
    const PriceSort = () => {
        if(sortItem === 'asc'){
            return(
                <button className="btn btn-box btn-outline-success d-flex flex-row text-green p-0 mx-2" onClick={()=>{sort('desc')}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="my-2 ms-2 bi bi-arrow-down" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                    </svg>
                    <p className="fs-normal-med my-2 mx-3">Price - Low to High</p>
                </button>
            )
        }
        else{
            return(
                <button className="btn btn-box btn-outline-success d-flex flex-row text-green p-0 mx-2" onClick={()=>{sort('asc')}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="my-2 ms-2 bi bi-arrow-down" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                    </svg>
                    <p className="fs-normal-med my-2 mx-3">Price - High to Low</p>
                </button>
            )

        }
    }

    const ClearFilter = () => {
        if(state){
            return(
                <button className ="btn btn-success text-white" onClick = {() => {clearFilter()}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-funnel text-white me-2" viewBox="0 0 16 16">
                        <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z"/>
                    </svg>
                    Clear Filters
                </button>
            )
        }
        else{
            return(
                <div className='col-2 col-md-2'></div>
            )
        }
    }

  return (
    <UserLayout>
        <FilterModal />
        <div className="container-fluid">  
            <div className="row justify-content-center">
            <div className='align-self-center my-md-200'>
                <div className="container d-block d-md-none d-lg-none">
                        <div className="search-bar d-flex flex-row justify-content-between">
                            <SearchMenu />
                            <button className="btn btn-box btn-outline-success ms-3 px-3 py-0" onClick={() => {filterModal()}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-funnel text-green" viewBox="0 0 16 16">
                                    <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z"/>
                                </svg>
                            </button>
                        </div>
                </div>
            
            <hr className='d-block mt-2 d-md-none d-lg-none'></hr>

            <div className='col-md-12 d-lg-flex justify-content-center'>
                <div className="col-md-8 col d-flex justify-content-between">
                    <PriceSort/>
                    <ClearFilter />
                </div>
            </div>
            <hr className='mt-2'></hr>
            <div className='col-md-12 d-flex justify-content-center'>
                <div className="col-md-8 d-flex justify-content-between">
                    <div className="d-lg-flex d-md-flex flex-row d-none">
                                <Link className="d-flex flex-row  a-nonlink" to="/landing">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#5CA772" className="bi bi-chevron-left mt-1" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                    </svg>
                                    <p className="fs-large-med btn-link text-green ms-2">Home</p>
                                </Link>
                                <p className="fs-large-light text-dark ms-2">/ Place Order</p>
                    </div>
                </div>
            </div>
            <hr className='mt-2 d-none d-lg-block d-md-block'></hr>

            <div className='col-md-12 d-lg-flex d-md-flex d-none justify-content-center mb-3 px-4'>
                <div className='col-md-8 px-2'>
                {(announcement!== null || (announcement.description !== null && announcement.description !== ""))&& anFlag?.flag!==1? 
                        <div className="card h-100 rounded-card p-4 mb-3 mt-3">
                            <div className='col-md-12 d-flex flex-row justify-content-between'>
                                <div className='col-md-8 d-flex flex-row'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-megaphone me-3 text-green" viewBox="0 0 16 16">
                                        <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49a68.14 68.14 0 0 0-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 74.663 74.663 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199V2.5zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0zm-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233c.18.01.359.022.537.036 2.568.189 5.093.744 7.463 1.993V3.85zm-9 6.215v-4.13a95.09 95.09 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A60.49 60.49 0 0 1 4 10.065zm-.657.975 1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68.019 68.019 0 0 0-1.722-.082z"/>
                                    </svg>
                                    <p className='fs-large-semibold text-green'>Announcements</p>
                                </div>
                                <svg   onClick={() => handleCloseAnnouncement()}xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-x-circle text-danger pointer" viewBox="0 0 16 16">
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>  
                            </div>
                            <p className='fs-normal-reg text-dark'>{announcement.description}</p>
                        </div> 
                        : null
            }
                </div>
            </div>
            
            <div className='d-lg-none d-block'>
            {(announcement!== null || (announcement.description !== null && announcement.description !== "")) && anFlag?.flag!==1? 
                        <div className="card h-100 rounded-card p-4 mb-3 mt-3">
                            <div className='col-md-12 d-flex flex-row justify-content-between'>
                                <div className='col-md-8 d-flex flex-row'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-megaphone me-3 text-green" viewBox="0 0 16 16">
                                        <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49a68.14 68.14 0 0 0-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 74.663 74.663 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199V2.5zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0zm-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233c.18.01.359.022.537.036 2.568.189 5.093.744 7.463 1.993V3.85zm-9 6.215v-4.13a95.09 95.09 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A60.49 60.49 0 0 1 4 10.065zm-.657.975 1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68.019 68.019 0 0 0-1.722-.082z"/>
                                    </svg>
                                    <p className='fs-large-semibold text-green'>Announcements</p>
                                </div>
                                <svg   onClick={() => handleCloseAnnouncement()}xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-x-circle text-danger pointer" viewBox="0 0 16 16">
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>  
                            </div>
                            <p className='fs-normal-reg text-dark'>{announcement.description}</p>
                        </div> 
                        : null
            }
            </div>
            {/* MOBILE DESIGN - START */}

            <div className="container px-0">
                {productList !== null ? productList.map((item, index) => (
                    <div className="col d-lg-none d-block">
                        <div className="col d-flex flex-row justify-content-between ms-2">
                            <p className="fs-large-semibold my-0">
                                {item.title}
                            </p>
                            {item.stock!==0 && item.stock >= user?.minQty ?
                                <CartButton skuRid={item.skuRid}/> : 
                                <p className='fs-normal-reg text-danger me-2'>Out of Stock</p>
                            }
                        </div> 

                        <div className="col-md-8 mt-2 d-flex flex-row justify-content-between ms-2">
                            <div className='col-4'>    
                                <p className='fs-small-reg mt-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                        <path fill="#5CA772" d="M19 19H5V5H19M19 3H5A2 2 0 0 0 3 5V19A2 2 0 0 0 5 21H19A2 2 0 0 0 21 19V5A2 2 0 0 0 19 3M7 9H9.31L9.63 6H11.63L11.31 9H13.31L13.63 6H15.63L15.31 9H17V11H15.1L14.9 13H17V15H14.69L14.37 18H12.37L12.69 15H10.69L10.37 18H8.37L8.69 15H7V13H8.9L9.1 11H7V9M11.1 11L10.9 13H12.9L13.1 11Z" />
                                    </svg>
                                    {item.skuCode}
                                </p>
                            </div>
                            <div className='col-6'>
                                <p className="fs-small-reg mt-2">{toPrefixedLocalePrice(item.basePrice)}<span className='text-grey fs-small-reg ps-2'>excl. of GST</span></p>
                            </div>
                            <div className='col-2'>    
                                <ClearButton skuRid={item.skuRid} />
                            </div>
                        </div> 
                        
                        <hr className="d-block d-md-none d-lg-none"></hr>

                    </div>
                    )) :
                    <div className="col-md-12 text-center">
                        <h1 className="fs-large-regular"><i>No records</i></h1>
                    </div>}
            </div>
            
             {/* MOBILE DESIGN - END */}

             {/* DESKTOP DESIGN - START */}

            <div className="d-lg-block d-none px-0">
                <div className="container-xxl">
                    <div className="row row-cols-xl-2 row-cols-xxl-2 px-5 mx-5">
                    {productList !== null ? productList.map((item, index) => (
                        <div className="d-flex  my-3">
                            <div className='col-md-12 card rounded-card w-100'>
                                <div className='col-md-8 container-sm p-5'>
                                    <div className="col d-flex flex-row ms-2">
                                        <p className="fs-x-large-semibold my-0">
                                            {item.title}
                                        </p>
                                        
                                    </div> 
                                    <div className="col d-flex flex-row ms-2">
                                        <p className='fs-large-reg me-5 mb-0 mt-2'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                <path fill="#5CA772" d="M19 19H5V5H19M19 3H5A2 2 0 0 0 3 5V19A2 2 0 0 0 5 21H19A2 2 0 0 0 21 19V5A2 2 0 0 0 19 3M7 9H9.31L9.63 6H11.63L11.31 9H13.31L13.63 6H15.63L15.31 9H17V11H15.1L14.9 13H17V15H14.69L14.37 18H12.37L12.69 15H10.69L10.37 18H8.37L8.69 15H7V13H8.9L9.1 11H7V9M11.1 11L10.9 13H12.9L13.1 11Z" />
                                            </svg>
                                            {item.skuCode}</p>
                                        <p className="fs-large-reg me-5 pe-5 mb-0 mt-2">{toPrefixedLocalePrice(item.basePrice)} <span className='text-grey fs-normal-reg ps-2'>excl of GST</span></p>
                                    </div> 
                                </div>
                                <div className='d-lg-flex row justify-content-center'>
                                    {item.stock!==0 && item.stock >= user?.minQty ?
                                        <CartButton skuRid={item.skuRid}/> : 
                                        <p className=' mt-3 text-center fs-normal-reg text-danger'>Out of Stock</p>
                                    }
                                </div>
                                <div className='col-md-12 d-flex justify-content-end mt-2 mb-2'>
                                    <div className='col-md-2 me-5'>
                                        <ClearButton skuRid={item.skuRid} />
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        )) :
                        <div className="col-md-12 text-center">
                            <h1 className="fs-large-regular"><i>No records</i></h1>
                        </div>}
                    </div>
                </div>
            </div>

            {/* DESKTOP DESIGN - END */}
            <div className='col-md-12 d-none d-lg-block mt-5'></div>
            <div className='col-md-12 d-none d-lg-block mt-5'></div>
            <div className='col-md-12 d-none d-lg-block mt-5'></div>
            
            {cartCount!==0 && cartCount!==null ?
                <div className="d-sm-block mb-0 ms-0 d-md-flex d-lg-flex justify-content-center sticky-div w-100">
                    <div className="col-10 col-md-6 ms-4 mb-2 text-left">
                        <Link to='/cart' className='a-nonlink'>
                            <button className="btn w-100 btn-box bg-green mx-auto">
                                <span className="fs-normal-reg text-light">Place order</span>
                            </button>
                        </Link>
                    </div>
                </div> 
            :null}
    </div>
    </div>
    </div>
    </UserLayout>
  )
}

export default PlaceOrder