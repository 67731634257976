import React, { useEffect, useRef, useState, useContext } from 'react'
import './product.scss';
import fanImg from '../../../assets/fan_img.svg';
import fanImg2x from '../../../assets/fan_image_2x.png';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router'
import UserLayout from '../../../UserLayout';
import ReactImageZoom from 'react-image-zoom';
import {
    Magnifier, GlassMagnifier,
    MOUSE_ACTIVATION,
    TOUCH_ACTIVATION,
    SideBySideMagnifier
} from "react-image-magnifiers";
import { ShimmerProduct } from '../../../components/shimmer-effect/ShimmerEffect';
import { getProductDetails, addToCart } from '../../../api/API';
import { CustomOptions } from '../login/Login';
import { useSnackbar } from 'react-simple-snackbar'
import { BASE_URL } from '../../../util/EnvHelper';
import { height } from '@mui/system';
import { toPrefixedLocalePrice } from '../../../util/Util';
import { getUser} from '../../../util/LocalStorageUtil';

import { CartContext } from '../../../CartContext';
import { Link, useParams } from "react-router-dom";


const Product = ({ match }) => {
    const navigate = useNavigate();
    const [incValue, setIncValue] = React.useState(4);
    const [isLoading, setIsLoading] = React.useState(true);
    const { state } = useLocation();
    const [user, setUser] = useState()
    const [moreProductDetails, setMoreProductDetails] = useState([]);
    const [attributeList, setAttributeList] = useState([]);
    const [imageList, setImageList] = useState([]);
    const [similarProductList, setSimilarProductList] = useState([]);
    const [zoomImage, setZoomImage] = React.useState(null);
    const [skuRid, setSkuRid] = useState(null);
    const [stock, setStock] = useState(null);
    const [notify, setNotify] = useState(false);
    const { pathname } = useLocation();
    const topRef = useRef(null);
    const [openSnackbar] = useSnackbar(CustomOptions)
    const { cartCount, getCount } = useContext(CartContext)
    const { pageId } = useParams();

    const doIncrement = (totalStock) => {
        if (incValue < totalStock && (moreProductDetails.stock - incValue) >= user?.minQty) {
            setIncValue(Number(incValue) + Number(user?.minQty))
        }
        else if((moreProductDetails.stock - incValue) <= user?.minQty){
            openSnackbar("Only "+(moreProductDetails.stock-incValue)+" in Stock, can't add more quantity")
            return
        }
    };

    const doDecrement = () => {
        if (incValue > 4) {
            setIncValue(Number(incValue) - Number(user?.minQty))
        }
    };

    const getProdDetails = async (skuRid) => {
        getProductDetails(skuRid)
            .then(data => {
                setStock(data.stock)
                setMoreProductDetails(data);
                setAttributeList(data.attributeList)
                setImageList(data.imageList)
                setSimilarProductList(data && data.similarProdList && data.similarProdList.length > 3 ? data.similarProdList.splice(0, 4) : data.similarProdList)
                setZoomImage(data.imageList[0])
                setIsLoading(false)
            }).catch(e => {
                setIsLoading(false)
                openSnackbar(e)
            })
    };

    const addCart = () => {

        if (incValue == 0) {
            openSnackbar('Please select quantity!')
            return
        }
        addToCart({ skuRid: skuRid, qty: (incValue) })
            .then((data) => {
                openSnackbar("Added to cart successfully")
                getCount();
                // viewMoreDetails(skuRid)
                navigate("/")

            })
            .catch(e => {
                setIsLoading(false)
                openSnackbar(e)
            })
        
    }

    const viewMoreDetails = (skuRid) => {
        setIncValue(user?.minQty)
        getProdDetails(skuRid)
        setSkuRid(skuRid)
        if (topRef.current) {
            window.scrollTo({
                behavior: "smooth",
                top: topRef.current.offsetTop
            });
        }
        navigate(`/product/${skuRid}`)
    }

    const NotifyButton = () => {
            if(notify){
                return(
                    <>
                        <button onClick = { () => {setNotify(false)}} className='btn btn-success text-white py-0 px-3'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2 me-2" viewBox="0 0 16 16">
                            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                        </svg> 
                        Notify Me
                        </button>
                    </>
                )
            }
            else{
                return(
                    <>
                        <button onClick = { () => {setNotify(true)}} className="btn btn-box btn-success text-green py-0 px-3">Notify Me</button>
                    </>
                )
            }
        }

    useEffect(() => {
        setUser(getUser())
        window.scrollTo(0, 0);
        if (state) {
            getProdDetails(state)
            setSkuRid(state)
        }
    }, [pathname])

    useEffect(() => {
        if (pageId) {
            if (topRef.current) {
                window.scrollTo({
                    behavior: "smooth",
                    top: topRef.current.offsetTop
                });
            }
            // navigate(`/product/${pageId}`)       // This makes the page navigate to the same URL / Page when clicking Back Button
            getProdDetails(pageId)
            setSkuRid(pageId)
        }
    }, [])

    return (
        <UserLayout>
            {isLoading ? (<ShimmerProduct />) :
                <div className="container-fluid align-self-center" ref={topRef}>

                    {moreProductDetails !== null ?

                        <>
                            <div className="container my-md-100">
                                <div className="row justify-content-between">

                                    <div className="col-md-1 w-10 justify-content-center  z-ind-0 d-none d-md-block d-lg-block" >
                                        {imageList !== null ?
                                            imageList.map((image, index) =>
                                                <img src={BASE_URL + "/" + image} className="img-fluid w-100 my-4" onClick={() => { setZoomImage(image) }} alt="" key={index} />
                                            )
                                            : null}
                                    </div>

                                    <div className="col-md-4  justify-content-center  z-ind-0 d-none d-md-block d-lg-block" >
                                        <div className="card border-0 card-non-b-radius my-4" >
                                            <div className="img-wrapper">
                                                <SideBySideMagnifier
                                                    imageSrc={BASE_URL + "/" + zoomImage}
                                                    alwaysInPlace={true}
                                                    imageAlt="fanImg"
                                                    largeImageSrc={BASE_URL + "/" + zoomImage}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-md-6 justify-content-center  z-ind-0" >
                                        <div className="row justify-content-center ">

                                            <div className="col-md-10">
                                                <p className="fs-large-regular mt-3 mb-4" >{moreProductDetails.title}</p>
                                                    {/* <button className="btn  rounded-btn fs-small-reg">
                                                        {moreProductDetails.prodName}
                                                    </button> */}
                                                <div className=" mb-4  d-block d-md-none d-lg-none w-100">
                                                    <div id="respCarousel" className="carousel carousel-dark slide" data-bs-interval="false" style={{ height: 300 }}>
                                                        <div className="carousel-inner" >
                                                            {imageList !== null ?
                                                                imageList.map((image, index) =>
                                                                    <div className={'carousel-item  ' + (index == 0 ? 'active' : '')} >
                                                                        <div className="card border-0 card-non-b-radius my-4 " >
                                                                            <SideBySideMagnifier
                                                                                imageSrc={BASE_URL + "/" + image}
                                                                                alwaysInPlace={true}
                                                                                imageAlt="fanImg"
                                                                                mouseActivation={MOUSE_ACTIVATION.DOUBLE_CLICK} // Optional
                                                                                touchActivation={TOUCH_ACTIVATION.DOUBLE_TAP}
                                                                                largeImageSrc={BASE_URL + "/" + image}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ) : null}
                                                        </div>
                                                        <button className="carousel-control-prev text-dark" type="button" data-bs-target="#respCarousel" data-bs-slide="prev">
                                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                            <span className="visually-hidden"></span>
                                                        </button>
                                                        <button className="carousel-control-next text-dark" type="button" data-bs-target="#respCarousel" data-bs-slide="next">
                                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                            <span className="visually-hidden"></span>
                                                        </button>
                                                        <div className="carousel-indicators insight-carousel-indicators">
                                                            {imageList !== null ?
                                                                imageList.map((image, index) =>
                                                                    <button type="button" data-bs-target="#respCarousel" data-bs-slide-to={index}
                                                                        className={index == 0 ? 'active' : ''} aria-current="true" key={index} ></button>
                                                                ) : null}
                                                        </div>

                                                    </div>
                                                </div>

                                                {/* CHECK TOTAL STOCK FOR NOTIFY */}

                                                <div className='col'>
                                                    {stock !== 0 ? 
                                                    null
                                                    : 
                                                    <div className='col d-flex flex-row justify-content-between mb-3'>
                                                        <div className='col d-flex flex-row text-danger mt-2'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle me-2" viewBox="0 0 16 16">
                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
                                                            </svg>
                                                            <p className='fs-large-med text-danger mb-0'>Presently not in stock</p>
                                                        </div>
                                                        <NotifyButton />
                                                    </div> }
                                                </div>
                                                

                                                <p>
                                                    <span className="fs-x-large-semibold me-2 mb-1">{toPrefixedLocalePrice(moreProductDetails.price)}</span>
                                                </p>

                                                <a className="mt-md-5 mt-3 d-block d-md-none d-lg-none" target="_blank" rel="noreferrer" href="https://api.tvsgreen.in/documents/price_list.pdf">
                                                    <button className="btn  btn-box w-100">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-file-earmark-text text-green" viewBox="0 0 16 16">
                                                            <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
                                                            <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                                                        </svg>
                                                        <span className="mx-3 fs-normal-reg text-green" >Price List</span>

                                                    </button>
                                                </a>

                                            </div>

                                            <div className="col-md-10 d-none d-md-block d-lg-block">
                                                <hr></hr>
                                                <div className=" d-flex flex-row justify-content-left">
                                                    <div className="col-md-4 me-3">
                                                        <a className="mt-md-5 mt-3" target="_blank" rel="noreferrer" href="https://api.tvsgreen.in/documents/price_list.pdf">
                                                            <button className="btn  btn-box w-100">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-file-earmark-text text-green" viewBox="0 0 16 16">
                                                                    <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
                                                                    <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                                                                </svg>
                                                                <span className="mx-3 fs-normal-reg text-green" >Price List</span>

                                                            </button>
                                                        </a>
                                                    </div>
                                                </div>

                                            {(moreProductDetails.stock) !== 0
                                            ?   
                                            <>
                                                <hr></hr>
                                                <div className=" d-flex flex-row justify-content-left">
                                                    <div className="col-md-4 me-3">
                                                        <div className="btn  btn-box w-100 d-flex flex-row justify-content-between ">
                                                            <button className=" btn my-auto p-0" onClick={() => { doDecrement() }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-dash-lg" viewBox="0 0 20 20">
                                                                <path fillRule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z" />
                                                            </svg></button>
                                                            <p className="mb-0 mx-2 my-auto fs-x-large-regular " >{incValue}pcs</p>

                                                            <button className="btn ms-0 my-auto p-0" onClick={() => { doIncrement(moreProductDetails.stock) }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 20 20">
                                                                <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                                            </svg></button>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <button onClick={addCart} className="btn  btn-box w-100 bg-green">
                                                            <p className="mb-0 fs-x-large-regular text-light">Add to cart</p>
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                                :null}

                                                {(moreProductDetails.stock - incValue) <= 5 && (moreProductDetails.stock - incValue) >= 0 ?
                                                    <div className="col-md-12 mt-4">
                                                        <p className="text-danger fs-normal-reg">Only {(moreProductDetails.stock - incValue)} stocks available</p>
                                                    </div> : null}
                                            </div>
                                            <div className="col-md-10 mt-3 mt-md-0">

                                                <hr></hr>
                                                <div className="accordion accordion-flush prod-accordion zi-0" id="detailsAccordion">
                                                    <div className="accordion-item bg-transparent">
                                                        <h2 className="accordion-header fs-x-large-regular my-auto" id="flush-headingDetails">
                                                            <button className="accordion-button collapsed  p-0 bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDetails" id="details" aria-expanded="false" aria-controls="collapseDetails">
                                                                &nbsp;&nbsp;&nbsp;Details
                                                            </button>
                                                        </h2>
                                                        <div id="collapseDetails" className="accordion-collapse collapse" aria-labelledby="flush-headingDetails" data-bs-parent="#detailsAccordion">
                                                            <div className="accordion-body px-0 u-list">
                                                                {attributeList !== null ?

                                                                    attributeList.map((item, index) => (
                                                                        <div className=" d-flex flex-row my-2" key={index}>
                                                                            <div className="col-md-6 ul-list col-6">
                                                                                <li><span className="fs-normal-light me-4">{item.attributeName}</span></li>
                                                                            </div>
                                                                            <div className="col-md-6 ul-list col-6">
                                                                                <li><span className="fs-normal-med">{item.value}</span></li>
                                                                            </div>

                                                                        </div>))
                                                                    : null}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <hr></hr>       

                                            </div>


                                        </div>

                                    </div>


                                    <div className="col-md-12 mt-2 mb-3 mt-md-3">
                                        <div className="row mt-3">
                                            <p className="fs-large-regular">Similar products</p>
                                            {similarProductList !== null ?

                                                similarProductList.map((item, index) => (

                                                    <div className="col-md-3 w-col-md-22 mb-3 col-6 pointer" onClick={() => { viewMoreDetails(item.skuRid) }} key={index}>
                                                        <div className="card h-100 rounded-card">
                                                            <div className="text-center mt-4 mt-md-5 mx-3">
                                                                <img src={BASE_URL + "/" + item.imageUrl} alt="" className="img-fluid w-100" />
                                                            </div>
                                                            <div className="card-body p-md-3 p-2 mt-2">
                                                                {/* <a>
                                                                    <button className="btn  rounded-btn fs-x-small-reg">
                                                                        {item.prodName}
                                                                    </button>
                                                                </a> */}
                                                                <p className="fs-normal-reg mt-1 mt-md-2 mb-0">{item.prodName}</p>
                                                                <p className="fs-normal-reg mt-md-2 mb-1">({item.skuName})</p>
                                                                <h1 className="fs-large-semibold  mt-1 mt-md-2"> {toPrefixedLocalePrice(item.price)}</h1>
                                                            </div>

                                                        </div>
                                                    </div>

                                                ))
                                                : null}

                                        </div>


                                    </div>

                                </div>
                            </div>

                            {(moreProductDetails.stock) !== 0
                            ?   
                            <div className="d-sm-block d-md-none d-lg-none">
                                <div className="row justify-content-between py-2 w-100 sticky-div" >
                                    <div className="col-6 ">
                                        <div className="btn  btn-box w-100 d-flex flex-row justify-content-between ">
                                            <button className=" btn my-auto p-0" onClick={() => { doDecrement() }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-dash-lg" viewBox="0 0 20 20">
                                                <path fillRule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z" />
                                            </svg></button>

                                            <p className="mb-0 mx-2 my-auto fs-x-large-regular " >{incValue}pcs</p>

                                            <button className="btn ms-0 my-auto p-0" onClick={() => { doIncrement(moreProductDetails.stock) }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 20 20">
                                                <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                            </svg></button>
                                        </div>
                                    </div>

                                    <div className="col-6 ">
                                        <button className="btn  btn-box bg-green w-100" onClick={addCart}>
                                            <div className=" d-flex flex-row justify-content-center ">
                                                <p className="mb-0 fs-x-large-regular text-light" >Add to cart</p>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            : null}
                            <br></br>
                        </> : <div className="col-md-12 text-center">
                            <h1 className="fs-large-regular"><i>No records</i></h1>
                        </div>

                    }
                </div>}
        </UserLayout>
    )
}
export default Product