import React from 'react';
import SearchIcon from "@mui/icons-material/SearchOutlined";
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useLocation } from 'react-router';

const SearchMenu = () => {
    
    const [searchParams] = useSearchParams()
    const params = Object.fromEntries([...searchParams]);
    const location = useLocation();

    const [searchQuery, setSearchQuery] = useState(params?.q ?? '')

    return (
        <div className="dropdown col-md-10">
            <form action={location.pathname === '/' ? 's-result' : location.pathname} onSubmit={(e) => {
                if (!searchQuery) {
                    e.preventDefault()
                    return
                }
            }} autoComplete='on' className="d-flex justify-content-between align-items-center searchbar" >
                <button className="border-0 bg-transparent text-primary " type="button" >
                    <SearchIcon />
                </button>
                <input type="text" name='q' id='searchField' onChange={(e) => setSearchQuery(e.target.value)} value={searchQuery} className="fs-x-large-regular search-input" placeholder="Search by Part No."/>
            </form>
        </div>
    )
}
export default SearchMenu