import React, { useEffect, useState } from "react";
import { Dropdown, Modal } from 'bootstrap';
import { Link, useNavigate } from "react-router-dom";
import ProfileLogo from '../../../assets/profile.svg';
import ProfileModal from "./ProfileModal";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { getUser, removeAdminAuthToken, removeAuthToken } from "../../../util/LocalStorageUtil";
const TopBar = ({ title }) => {

    const navigate = useNavigate()
    const [user, setUser] = useState()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [isLoading, setIsLoading] = useState(false)
    const [showPIndex, setShowPIndex] = useState(-1)
    useEffect(() => {
        setUser(getUser())
    }, [])

    useEffect(() => {
        if (showPIndex > -1) {
            const modalProfleDetails = new Modal(document.querySelector('#modalProfile'), {
                keyboard: false
            })
            modalProfleDetails.show()
        }
    }, [showPIndex])

    const handleClickDropdown = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        removeAuthToken()
        handleClose()
        navigate('/admin/login', { replace: true })
    }


    const ShowProfileModal = () => {
        return showPIndex > -1 ? (<ProfileModal userName={user?.fullName} onModalClose={() => addProfileData(-1)}
        />) : (<></>)
    }

    const addProfileData = (index) => {
        handleClose()
        setShowPIndex(index)
    }
    return (
        <>
            <ShowProfileModal />

            <div className="p-3 bg-white border-bottom d-flex justify-content-between align-items-center">
                <h4 className="mb-0 ml-5 fs-x-large-semibold" style={{ marginLeft: 10 }}>
                    {title}
                </h4>

                <div className="custom-profile-menu">
                    <Button
                        id="dropdown-btn"
                        aria-controls={open ? 'dropdown-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClickDropdown}
                    >
                        <button type="button" className="b-none px-4 py-1 float-end rect-profile align-items-center pointer " >
                            <span className="fs-large-regular me-2">
                                {user?.fullName}
                            </span>
                        </button>
                    </Button>
                    <Menu
                        id="dropdown-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'dropdown-btn',
                        }}
                    >
                        {/* <MenuItem onClick={() => { addProfileData(1) }}>
                            <EditOutlinedIcon fontSize="small" className="text-primary" />
                            <span className="fs-large-regular ms-3">Profile</span>
                        </MenuItem> */}
                        <MenuItem onClick={logout}>
                            <LogoutOutlinedIcon fontSize="small" className="text-primary" />
                            <span className="fs-large-regular ms-3">Logout</span>
                        </MenuItem>
                    </Menu>
                </div>
            </div>




        </>


    )

}
export default TopBar