import React, { useEffect, useState } from 'react'
import './VendorList.scss';
import { Modal } from 'bootstrap'
import Pagination from '@mui/material/Pagination';
import AdminLayout from '../../../AdminLayout';
import SearchIcon from "@mui/icons-material/SearchOutlined";
import VendorEditAddModal from './VendorEditModal';
import VendorDeleteModal from './VendorDeleteModal';
import { getPartnerList,getSalesHierarchy,getCategoryList,vendorListExport } from '../../../api/API';
import { ShimmerPartner } from '../../../components/shimmer-effect/ShimmerEffect';
import { paginationStyle } from '../../../scss/admin/mui';
import { Stack } from '@mui/material';
import VendorAddModal from './AddVendorModal';
import { PAGINATION_ITEM_PER_PAGE } from '../../../util/EnvHelper';

const VendorList = () => {
    const [showPIndex, setShowPIndex] = useState(-1)
    const [deletePIndex, setDeletePIndex] = useState(-1)
    const [showAIndex, setShowAIndex] = useState(-1)

    const [isViewOnly, setIsViewOnly] = useState(false)
    const [isLoading, setIsLoading] = React.useState(true);
    const [state, setState] = React.useState({
        partenerList: [], noOfPages: 0, 
    });
    const [filterList, setFilterList] = useState([state.partenerList]);
    const [salesHierarchyList,setSalesHierarchyList] = useState()
    const [categoryList, setCategoryList] = useState([]);

    const itemsPerPage = PAGINATION_ITEM_PER_PAGE;
    const [page, setPage] = React.useState(1);
    let officer=  null
    let partnerOfficer = null
    let categoryName = null

  useEffect(() => {
    getPartner()
    getCategory()
    getHeirarchy(3)
  }, []);

  const getHeirarchy = (getSh) => {
    getSalesHierarchy(getSh)
        .then(data => {
            setIsLoading(false)
            setSalesHierarchyList(data)
        })
    
}

  const getPartner = () => {
    getPartnerList()
        .then(data => {
            setIsLoading(false)
            setState(
                {
                    ...state,
                    partenerList: data,
                    noOfPages: Math.ceil(data.length / itemsPerPage)

                })
                setFilterList(data)
        })
}

const getCategory = async () => {
    getCategoryList()
        .then(data => {
            if (data) {
                setCategoryList(data)
            }
        }).catch(e => {
        })
};
 
useEffect(() => {
    if (showPIndex > -1) {
        const modalVendorDetails = new Modal(document.querySelector('#vendorDetailsModal'), {
            keyboard: false
        })
        modalVendorDetails.show()

    }
}, [showPIndex])

useEffect(() => {
    if (deletePIndex > -1) {
        const modalDelVendor = new Modal(document.querySelector('#deleteVendorModal'), {
            keyboard: false
        })
        modalDelVendor.show()
    }
}, [deletePIndex])

useEffect(() => {
    if (showAIndex > -1) {
        const addVendor = new Modal(document.querySelector('#addNewVendorModal'), {
            keyboard: false
        })
        addVendor.show()

    }
}, [showAIndex])
    
  const setVendorModalData = (index, value) => {
    setShowPIndex(index)
    setIsViewOnly(value)
}

const deleteVendorData = (index) => {
    setDeletePIndex(index)
}

const addVendorData = (index) => {
    setShowAIndex(index)
}

const EditModal = () => {
    partnerOfficer = state.partenerList[showPIndex]
    if(partnerOfficer){
        categoryName = categoryList.find(name=>name.pcRid===partnerOfficer.partCategory)
        officer = salesHierarchyList.find(officer => partnerOfficer.shRid === officer.shRid)
    }
    return showPIndex > -1 ? (<VendorEditAddModal partner={state.partenerList[showPIndex]}
        getVendorList={() => getPartner()} officer={officer} categoryName={categoryName} isViewOnly={isViewOnly} onModalClose={() => setVendorModalData(-1)}
    />) : (<></>)
}

const DeleteModal = () => {
    return deletePIndex > -1 ? (<VendorDeleteModal partner={state.partenerList[deletePIndex]}
        onModalClose={() => deleteVendorData(-1)} getVendorList={() => getPartner()} />) : (<></>)
}

const AddModal = () => {
    return showAIndex > -1 ? (<VendorAddModal getVendorList={() => getPartner()} onModalClose={() => addVendorData(-1)}
    />) : (<></>)
}

const onPageChange = (event, value) => {
    setPage(value);
};


    const searchFliter = (e) => {
        setPage(1);
        let searchText = e.target.value.toUpperCase();
        let searchResult = [];
     
        searchResult = (state.partenerList).filter((item) => {
        const partName = item.partName ? item.partName.toUpperCase() : ''.toUpperCase();
        const address1 = item.address1 ? item.address1.toUpperCase() : ''.toUpperCase();
        const address2 = item.address2 ? item.address2.toUpperCase() : ''.toUpperCase();
        const address3 = item.address3 ? item.address3.toUpperCase() : ''.toUpperCase();
            return (partName.search(searchText) !== -1 || address1.search(searchText) !== -1
                || address2.search(searchText) !== -1 || address3.search(searchText) !== -1);
        });

        setFilterList(searchResult);

}

const downloadReport = (type) => {
    vendorListExport(type)
        .then(res => {
            // console.log(res);
            const filename = res.headers['content-disposition'].split('"')[1]
            console.log(filename);
            // const filename='file'
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download',filename);

            document.body.appendChild(link);
            link.click();
            })
}
// console.log(state.partenerList)

  return (
  
        <AdminLayout title="Partner">
            <EditModal />
            <AddModal />
            <div className="container-fluid align-self-center">
                <div className="row justify-content-center">

                    <div className="col-md-12  d-flex flex-row justify-content-between">
                        <div className="search-input-btn d-flex flex-row justify-content-between border-0 rounded-bg px-3 col-md-4">

                            <div className="my-auto">
                                <SearchIcon />
                            </div>
                            <input type="text" className="form-control border-0 bg-transparent fs-large-regular "
                                placeholder="Search by Partner" onChange={(e) => { searchFliter(e) }} />
                        </div>

                        <div className='col d-flex ms-5'>
                            <button onClick={() => {downloadReport(1)}} className="btn justify-content-center align-items-center d-flex p-0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-file-earmark-excel text-primary" viewBox="0 0 16 16">
                                    <path d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z" />
                                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                </svg>
                            </button>
                            <button onClick={() => {downloadReport(2)}} className="btn justify-content-center text-danger align-items-center d-flex p-0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"/>
                                </svg>
                            </button>
                        </div>

                        <div className="my-auto">
                            <button className="px-4 py-2 b-none w-100 rounded-bg fs-normal-semibold text-green "
                                onClick={() => { addVendorData(1) }}>
                                + Add new
                            </button>
                        </div>
                    </div>

                    <div className=" col-md-12  p-3 table-container mt-3">
                        <table className="table table-striped table-borderless  ">
                            <thead>
                                <tr className="fs-large-light">
                                    <td scope="col" >
                                        <div className="d-flex flex-row my-auto">
                                            <div className="my-auto ">
                                                <p className="me-2 mb-0 ws-no-wrap">Partner Name</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="col">
                                        <div className="d-flex flex-row my-auto">
                                            <div className="my-auto">
                                                <p className="me-2 mb-0 ws-no-wrap">Partner Code</p>
                                            </div>
                                        </div>
                                    </td>
                                    {/* <td scope="col" >
                                        <div className=" d-flex flex-row my-auto">
                                            <div className="my-auto">
                                                <p className="me-2 mb-0">Location</p>
                                            </div>
                                            <div className="d-flex flex-column ">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-caret-up text-grey " viewBox="0 0 16 16" >
                                                    <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-caret-down text-grey" viewBox="0 0 16 16">
                                                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </td> */}
                                    <td scope="col" >
                                        <div className="d-flex flex-row my-auto">
                                            <div className="my-auto">
                                                <p className="me-2 mb-0">Address</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="col" >
                                        <div className=" d-flex flex-row my-auto">
                                            <div className="my-auto">
                                                <p className="me-2 mb-0">Status</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="col " >
                                        <div className=" d-flex flex-row my-auto">
                                            <div className="my-auto">
                                                <p className="me-2 mb-0">Actions</p>
                                            </div>

                                        </div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                            {isLoading ? (<ShimmerPartner />) :
                               filterList !== null ?
                               filterList
                                        .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                                        .map((partner, index) => {
                                            return (
                                                
                                                    <tr  key={filterList.indexOf(partner)} className="align-middle fs-normal-semibold" >

                                                        <td scope="row">{partner.partName}</td>
                                                        <td className="text-center">{partner.partCode}</td>
                                                        {/* <td>Lansing, Illinois</td> */}
                                                        <td>
                                                            {partner.address1} <br />
                                                            {partner.address2} <br />
                                                            {partner.address3} <br />
                                                        </td>
                                                        <td>
                                                            {partner.status === 1 ? 'Active' : 'Inactive'}
                                                        </td>
                                                        <td>
                                                            <div className="d-flex flex-row justify-content-center">
                                                                <button className="action-circle me-2" onClick={() => setVendorModalData(state.partenerList.indexOf(partner), true)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye text-green" viewBox="0 0 16 16">
                                                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                                                    </svg>
                                                                </button>
                                                                <button className="action-circle me-2" onClick={() => setVendorModalData(state.partenerList.indexOf(partner), false)} >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil text-green" viewBox="0 0 16 16">
                                                                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                                    </svg>
                                                                </button>
                                                                {/* <button className="action-circle me-2" onClick={() => deleteVendorData(state.partenerList.indexOf(partner))}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash text-danger" viewBox="0 0 16 16"
                                                                    >
                                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                                    </svg>
                                                                </button> */}
                                                            </div>
                                                        </td>
                                                    </tr>

                                                
                                            );
                                        }) :
                                    <tbody> <td className=" w-3"></td>
                                        <td scope="row"></td>
                                        <td scope="row"><div className="w-100 mt-3 text-center justify-content-center">
                                            <h1 className="fs-large-regular"><i>No records</i></h1>
                                        </div></td></tbody>
                            }
                            </tbody>
                        </table>

                        <div className=" mt-5 justify-content-center text-center">
                            <Stack spacing={2} alignItems="center">
                                <Pagination
                                    count={Math.ceil((filterList).length / itemsPerPage)}
                                    page={page}
                                    onChange={onPageChange}
                                    defaultPage={1}
                                    size="large"
                                    variant="outlined" color="success"
                                    showFirstButton
                                    showLastButton
                                  />
                                  
                            </Stack>
                        </div>
                    </div>
                </div>
            </div>

            {/* Vendor view and edit modal */}

            {/* Vendor view and edit modal */}

        </AdminLayout>

  );
}
export default VendorList