import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProfileLogo from '../../../assets/profile.svg';
import { useNavigate } from 'react-router'
import { CustomOptions } from "../../user/login/Login";
import { useSnackbar } from 'react-simple-snackbar';
import { adminChangePassword } from "../../../api/API";
import { ADMIN_TYPE } from '../../../util/EnvHelper'

const ProfileModal = ({ userName, onModalClose }) => {

    const [openSnackbar] = useSnackbar()
    const [isSubmit, setIsSubmit] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState('');

    const navigate = useNavigate()

    const [state, setState] = useState({
        newPassword: '', oldPassword: '', confirmPassword: ''
    })
    const handleChange = (e, key) => {
        setState({
            ...state, [key]: e.target.value
        })
    }
    const changePassword = () => {

        if (!state.oldPassword) {
            setAlertMsg("Old password cannot be empty")
            setShowAlert(true)

            return
        }

        if (!state.newPassword) {
            setAlertMsg("New password cannot be empty")
            setShowAlert(true)
            return
        }
        if (!state.newPassword.match(state.confirmPassword)) {
            setAlertMsg("Password not matching")
            setShowAlert(true)
            return

        }
        setIsSubmit(true)

        adminChangePassword({ oldPassword: state.oldPassword, newPassword: state.newPassword, userType: ADMIN_TYPE })
            .then(() => {
                setIsSubmit(false)
                navigate('/dashboard')
                onModalClose()
            }).catch(e => {
                setIsSubmit(false)
                openSnackbar(e)
                onModalClose()

            })
        dismissAlert()
    }

    const dismissAlert = () => {
        setAlertMsg(''); setShowAlert(false)
    }
    return (
        <div className="modal fade  custom-modal  m-backdrop" id="modalProfile" tabIndex="-1"
            data-bs-backdrop="false" aria-labelledby="profileModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content max-w-400">
                    <div className="modal-body p-2rem">
                        <div className="text-center ">
                            <img src={ProfileLogo} alt="TVS Logo" width="80" className="img-fluid mt-neg-50" />
                            <button type="button" className=" float-end px-0 custom-btn" data-bs-dismiss="modal" aria-label="Close" onClick={onModalClose}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-x-circle float-end text-primary" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </button>
                        </div>
                        <div className="text-center mt-3 mb-4">
                            <h1 className="fs-small-title-medium">{userName}</h1>
                            <p className="fs-large-med text-primary">VENDOR</p>
                        </div>

                        <div>
                            <p className="fs-normal-semibold">CHANGE PASSWORD</p>
                            <input type="password" required
                                className="form-control login-input p-07rem mb-3 w-100 fs-large-regular px-4"
                                placeholder="Old password" onChange={(e) => { handleChange(e, 'oldPassword') }} />
                            <input type="password" required
                                className="form-control login-input p-07rem mb-3 w-100 fs-large-regular px-4"
                                placeholder="New password" onChange={(e) => { handleChange(e, 'confirmPassword') }} />
                            <input type="password"
                                required className="form-control login-input p-07rem mb-3 w-100 fs-large-regular px-4"
                                placeholder="Re-enter new password" onChange={(e) => { handleChange(e, 'newPassword') }} />
                            {isSubmit ? <button className="btn btn-primary br-21 fs-large-semibold text-light px-5 py-2 w-100">
                                <div className="spinner-border text-white spinner-border-sm " role="status"></div>  Save changes</button> :
                                <button onClick={() => { changePassword() }} type="btn" className="btn bg-green btn-focus br-21 p-07rem mb-3 w-100 text-light fs-large-semibold"
                                >Save changes</button>}
                        </div>
                        {showAlert ?
                            <div className="alert alert-dismissible mt-3 fade show text-primary fs-normal-med" role="alert">
                                {alertMsg}
                                <button type="button" className=" float-end px-0 custom-btn my-auto" data-bs-dismiss="alert" aria-label="Close" onClick={() => { dismissAlert() }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle float-end text-primary" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </button>
                            </div>
                            : null}
                    </div>


                </div>
            </div>
        </div >
    )



}
export default ProfileModal