import React, {useState, useEffect} from "react";
import { useSnackbar } from 'react-simple-snackbar'
import { CustomOptions } from '../login/Login';



const DeleteModal = (props) => {
    
    const [state, setState] = React.useState({
        gilad: true,

    });

    const [openSnackbar] = useSnackbar(CustomOptions)

    const deleteItem = async (e) => {
        console.log(e.target.value)
        props.formData({delItem: e.target.value})
    }

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

    useEffect(() => {
        window.onpopstate = e => {
            window.location.reload()
        }
    },[])

    return (
        <div className="modal fade custom-modal" id="deleteModal" tabIndex="-1" aria-labelledby="cartDeleteModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body px-0">
                        <div className="container  p-2rem">
                            <div className="row">
                                <div className="col-md-12 my-auto mb-2">
                                    <p className="float-start fs-large-med text-green">
                                        Delete
                                    </p>
                                    <button type="button" className=" float-end px-0 custom-btn bg-transparent border-0" data-bs-dismiss="modal" aria-label="Close">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-circle float-end text-primary" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </button>
                                </div>
                                <p className="my-auto float-start fs-large-regular my-3">Are you sure you want to delete this item</p>
                            </div>
                        </div>
                        <div className="modal-footer pb-0">
                            <button onClick={(e) =>  {deleteItem(e)}} value={false} type="button" className="btn btn-secondary fs-large-med me-3" data-bs-dismiss="modal">Cancel</button>
                            <button onClick={(e) =>  {deleteItem(e)}} value={true} className="btn btn-danger br-21 fs-large-semibold text-light px-5 py-2" data-bs-dismiss="modal">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteModal