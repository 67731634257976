
let inValidMessege = "",
    formIsValid = true,
    fieldIsValid = true,
    isFormValidation = true;

const EMAIL_EXP = /^[^\s()<>@,;:\/]+@\w[\w\.-]+\.[a-z]{2,}$/i,
    STRING_EXP = /[a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/,
    INT_EXP = /^[-+]?\d+$/;


// function validateField({ field, onValid, onInValid }) {

//     field.change(function () {
//         validateItem(field);
//         fieldIsValid ? onValid() : onInValid();
//     });
// }

export function validateForm(form) {

    isFormValidation = formIsValid = true;

    const feildsList = form.querySelectorAll('.validate-me')

    feildsList.forEach(e => {
        validateItem(e);
        e.onchange = () => validateItem(e)
    })

    return formIsValid
}

function validateItem(field) {
    fieldIsValid = true;
    const rulesStr = field.getAttribute('data-rule');
    const messege = field.getAttribute('data-msg');

    if (rulesStr !== null) {
        let isValid = 1;
        const rules = rulesStr.split('|');
        let ruleIndex = 0;
        let messeges = []
        if (messege !== null) {
            messeges = messege.split('|');
            inValidMessege = messege[ruleIndex];
        }
        while (ruleIndex < rules.length) {
            let currentRule = rules[ruleIndex];
            inValidMessege = "";
            if (messege != null) {
                inValidMessege = messeges[ruleIndex];
            }
            let pos = currentRule.indexOf(':', 0);
            if (pos >= 0) {
                const exp = currentRule.substr(pos + 1, currentRule.length);
                const rule = currentRule.substr(0, pos);
                isValid = validate(field, rule, exp);

            } else {
                const rule = currentRule.substr(pos + 1, currentRule.length);
                isValid = validate(field, rule, 0);
            }

            if (isValid === 0) {
                fieldIsValid = formIsValid = false;
                break;
            }

            ruleIndex++;
        }

        if (isValid === 0) {
            field.classList.add('is-invalid');
            field.classList.remove('is-valid');
            const inValidMessageDiv = field.parentNode.querySelector('.invalid-feedback')
            if (inValidMessageDiv)
                inValidMessageDiv.textContent = inValidMessege;

            if (isFormValidation) { field.focus(); }

        } if (isValid === 1) {
            // field.classList.add('is-valid');
            field.classList.remove('is-invalid');
            // const validMessageDiv = field.parentNode.querySelector('.valid-feedback')
            // if (validMessageDiv)
            //     validMessageDiv.textContent = "Looks Good!";
        }
    }
}


function validate(field, rule, exp) {
    const { value, files } = field
    switch (rule) {
        case 'required':
            if (value === '') {
                inValidMessege = inValidMessege === "" ? "This field is mandatory" : inValidMessege;
                return 0;
            }
            break;

        case 'min':
            if (value === "") {
                return 2;
            }
            if (value.length < parseInt(exp)) {
                inValidMessege = inValidMessege === "" ? "Min length is " + exp : inValidMessege;
                return 0;
            }
            break;

        case 'max':
            if (value === "") {
                return 2;
            }
            if (value.length > parseInt(exp)) {
                inValidMessege = inValidMessege === "" ? "Max length is " + exp : inValidMessege;
                return 0;
            }
            break;

        case 'email':
            if (value === "") {
                return 2;
            }
            if (!EMAIL_EXP.test(value)) {
                inValidMessege = inValidMessege === "" ? "Invalid email" : inValidMessege;
                return 0;
            }
            break;

        case 'integer':
            if (value === "") {
                return 2;
            }
            if (!INT_EXP.test(value)) {
                inValidMessege = inValidMessege === "" ? "Invalid Input" : inValidMessege;
                return 0;
            }
            break;

        case 'select':
            if (value === '-1') {
                inValidMessege = inValidMessege === "" ? "This field is mandatory" : inValidMessege;
                return 0;
            }
            break;

        case 'mimes':
            if (value === "") {
                return 2;
            }
            let formats = exp.split(','),
                formatsIndex = 0,
                imageExt = files[0].type;

            while (formatsIndex < formats.length) {
                if (imageExt === "image/" + formats[formatsIndex]) {
                    return 1;
                }
                formatsIndex++;
            }
            inValidMessege = inValidMessege === "" ? "file format not supported" : inValidMessege;
            return 0;

        case 'maxsize':
            if (value === "") {
                return 2;
            }
            let fileSize = files[0].size / 1024;
            if (fileSize > parseInt(exp)) {
                inValidMessege = inValidMessege === "" ? "Max file size is " + exp + "KB" : inValidMessege;
                return 0;
            }
            break;

    }

    return 1;

}