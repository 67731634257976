import React, { useState } from 'react'
import OtpPassword from '../../../components/otp-password/Otp-Password'
import backGround from '../../../assets/banner1.png'
import { Link } from 'react-router-dom'
import { CustomOptions } from './Login'
import { useSnackbar } from 'react-simple-snackbar'
import LoadingButton from '../../../components/loading-button/LoadingButton'
import { useNavigate } from 'react-router'
const Otp = () => {
    const [otp, setOtp] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const [openSnackbar] = useSnackbar(CustomOptions)

    const sendOtp = () => {
        if (!otp || !(otp.match('[0-9]'))) {
            openSnackbar("Enter valid OTP!")
            return
        }
        // setIsLoading(true)
        navigate('/forgot-password')
    }
    return (
        <>
            <img className="banner" src={backGround} alt="banner" />
            <div className="container  mt-md-50">
                <div className="row justify-content-center">
                    <div className="col-md-4 p-2rem my-auto login-container col-11">
                        <div className="flex-row d-flex mb-3">
                            <a className="a-nonlink" href="/">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-left my-auto" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                </svg>
                            </a>
                            <div className="ms-4 my-auto"><span className="text-center fs-l-large-regular">Verify OTP</span></div>
                        </div>
                        <p className="fs-large-light pb-3">An OTP has been sent to your entered mobile number. Enter the OTP to proceed.</p>
                        <input type="password" onChange={(e) => setOtp(e.target.value)}
                            className="form-control login-input p-07rem my-4 w-100 fs-x-large-regular px-4" placeholder="Enter OTP" />
                        <div>
                            <LoadingButton
                                buttonText="Verify OTP"
                                loadingText="SENDING..."
                                width="w-100"
                                onClick={sendOtp}
                                isLoading={isLoading}
                            />

                        </div>
                    </div></div></div>



        </>
    )
}
export default Otp