import React ,{useEffect, useState} from 'react';
import {toPrefixedDashboardPrice,toDashboardPrice, toLocalePrice} from '../../../util/Util'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const Rechart = (data) => {
    
    // useEffect(() => {
    //   console.log(data);
    // })
    
    const formatYAxis = (tick) => {
        return toPrefixedDashboardPrice(tick)
    }
    
    return (
        <LineChart
            width={1022}
            height={300}
            data={data.data}
            margin={{
                top: 25,
                right: 0,
                left: 20,
                bottom: 5
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis  dataKey="date" padding={{ left: 30, right: 30 }} />
            <YAxis tickFormatter={(tick) => formatYAxis(tick)}/>
            <Tooltip />
            <Legend />
            <Line
                type="monotone"
                dataKey="amount"
                name='Sales'
                stroke="#5CA772"
                activeDot={{ r: 8 }}
            />

        </LineChart>

    )
}
export default Rechart