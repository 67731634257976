import React from "react";
import { deleteSalesHierarchy } from "../../../api/API";
import { CustomOptions } from '../../user/login/Login';
import { useSnackbar } from 'react-simple-snackbar';

const DeleteHierarchy = ({ partner, onModalClose }) => {
    const [openSnackbar] = useSnackbar(CustomOptions)
    console.log(partner);
    
    const deleteOfficer = () => {
        deleteSalesHierarchy(partner.shRid)
        .then((description)=>{
            openSnackbar(description)
            onModalClose()
        })
        .catch((e)=>{
            openSnackbar(e)
            onModalClose()
        })
    }

    return (
        <div className="modal fade custom-modal m-backdrop" data-bs-backdrop="static" id="deleteHierarchy" tabIndex="-1" aria-labelledby="vendorEditAddModalLabel" aria-hidden="true">
            <div className="modal-dialog max-w-350 modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body p-2rem">
                        <p className="fs-large-med">
                            Are you sure you want to delete
                            the entry?
                        </p>
                        <p className="fs-large-med text-grey">
                            {partner.fullName}
                        </p>

                        <div className="modal-footer b-none">
                            <button type="button" className="btn btn-secondary fs-large-med " onClick={onModalClose} data-bs-dismiss="modal">Cancel</button>
                            <button onClick={() => {deleteOfficer()}}  className="btn btn-danger br-21 fs-large-semibold text-light px-5 " data-bs-dismiss="modal">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DeleteHierarchy