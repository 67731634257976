import React, {useEffect, useState} from 'react'
import UserLayout from '../../../UserLayout'
import { Link } from 'react-router-dom'
import { getSalesContact,submitFeedback } from '../../../api/API'
import { useNavigate } from 'react-router-dom';
import { CustomOptions } from '../login/Login';
import { useSnackbar } from 'react-simple-snackbar'
import { Modal } from 'bootstrap'
import FeedbackModal from './FeedbackModal';

const Contact = () => {

    const [salesContact,setSalesContact] = useState([]);
    const [openSnackbar] = useSnackbar(CustomOptions)
    const [feedb,setFeedb]  = useState(null)
    const navigate = useNavigate();

    const onInputChange = (feedback) => {
        setFeedb(feedback)
    }

    const feedbackSubmit = () => {
        if(feedb === '' || feedb === null ){
            openSnackbar("Please enter feedback")
        }
        else{
            submitFeedback({feedback:feedb})
            .then((description) => {
                // openSnackbar(description)
            })
            const openFeedbackModal = new Modal(document.querySelector('#feedbackModal'), {
                keyboard: false
            })
            openFeedbackModal.show()
        }
        
    }


    useEffect(() => {
        console.log(feedb)
        getSalesContact()
        .then((data) => {
            setSalesContact(data)
        })
        .catch(e => {
            openSnackbar(e)
        })
    },[])
    
  return (
    <UserLayout>
        <FeedbackModal />
        <div className = "container-fluid align-self-center">
            <div className="row justify-content-center">
                <div className='container my-md-200'>
                <div className="row justify-content-center">
                    <div className="col-md-7 col-11 px-0">
                    <div className="col-md-8 d-lg-block d-md-block d-none">
                        <div className="mb-3">
                            <div className="d-flex flex-row ">
                                <Link className="d-flex flex-row  a-nonlink" to="/landing">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#5CA772" className="bi bi-chevron-left mt-1" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                    </svg>
                                    <p className="fs-large-med btn-link text-green ms-2">Home</p>
                                </Link>
                                <p className="fs-large-light text-dark ms-2">/ Contact</p>
                            </div>
                        </div>
                    </div>

                    <p className="fs-normal-med text-green mt-4">SALES OFFICER</p>

                    <div className = "col d-flex flex-row justify-content-between">
                        <p className="fs-large-semibold">{salesContact.name}</p>
                        <button type="button" className="btn btn-success fs-small text-white px-3 py-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                            </svg>
                            <span className="fs-small text-white ps-3" >Call</span>
                        </button>
                    </div>

                    <div className="col d-flex flex-row">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-telephone text-green" viewBox="0 0 16 16">
                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                        </svg>
                        <p className='fs-normal-reg ps-3'>{salesContact.contactNo}</p>
                    </div>

                    <div className="col d-flex flex-row">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-envelope text-green" viewBox="0 0 16 16">
                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                        </svg>
                        <p className='fs-normal-reg ps-3'>s{salesContact.email}</p>
                    </div>

                    <hr></hr>

                    <div className="col">
                        <p className='fs-large-regular mb-1 mt-4'>Feedback</p>
                        <div className='input-group'>
                            <textarea type="text" onChange={(e) => {onInputChange(e.target.value)}} placeholder="Enter your feedback/ queries here" className='fs-normal-reg w-100 pb-5'></textarea>
                        </div>
                        <button onClick = {() => {feedbackSubmit()}} type="button" className='btn btn-success w-100 fs-large-semibold text-white px-5 mt-2'>Submit</button>
                    </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        
    </UserLayout>
  )
}

export default Contact
