import React, { useEffect, useState } from "react";
import { toDisplayDateMonthTime } from "../../../util/Util";

const ViewFeedback = ({ feedbackDetails, onModalClose }) => {

    const [feedListDetails, setFeedListDetails] = useState([feedbackDetails])

    useEffect(() => {
        window.onpopstate = e => {
            window.location.reload()
        }
        setFeedListDetails(feedbackDetails)
    })
    return (
        feedListDetails ? (
            <div className="modal fade custom-modal" id="feedbackViewModal" tabIndex="-1" aria-labelledby="vendorEditAddModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg max-w-600 modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header" style={{ borderBottom: 0 }}>

                            <h1 className="fs-normal-semibold text-primary my-auto">Feedback</h1>

                            <button type="button" className=" my-auto custom-btn" data-bs-dismiss="modal" aria-label="Close" onClick={onModalClose}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-circle float-end text-primary" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </button>

                        </div>
                        <div className="modal-body ">
                            <div className="containerpt-0 vendor-modal-body">
                                <div className="row">

                                    <div className=" row mx-0 mt-2">
                                        <div className="col-md-6">
                                            <label className="fs-normal-reg mb-2">Customer name</label>
                                            <input
                                                type="text"
                                                className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                                disabled={true}
                                                name="name" value={feedListDetails.contactPerson}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="fs-normal-reg mb-2">Mobile number</label>
                                            <input
                                                type="text"
                                                className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"

                                                disabled={true}
                                                name="contact" value={feedListDetails.mobile1}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="fs-normal-reg mb-2">Email address</label>
                                            <input
                                                type="text"
                                                className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                                disabled={true}
                                                name="email" value={feedListDetails.email}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="fs-normal-reg mb-2">Submitted on</label>
                                            <input
                                                type="text"
                                                className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                                disabled={true}
                                                name="email" value={toDisplayDateMonthTime(feedListDetails.createdAt)}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <label className="fs-normal-reg mb-2">Feedback</label>
                                            <textarea
                                                className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                                disabled={true}
                                                name="feed" value={feedListDetails.feedback}
                                                rows={4}
                                            >
                                                {feedListDetails.feedback}
                                            </textarea>
                                        </div>

                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        ) : (null)
    )
}
export default ViewFeedback