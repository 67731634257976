import axios from "axios";
import { camelizeKeys, decamelizeKeys } from 'humps'
import { BASE_URL } from "../util/EnvHelper";
import { getAuthToken, removeAuthToken, removeUser, setAuthToken, setUser } from "../util/LocalStorageUtil";


// open urls to exclude headers and 401 errors
const OPEN_URL_LIST = [
    'init-login'
]

const FILE_URL_LIST = [
    'generate-proforma-invoice'
]

const AUTHENTICATE = 'login'

const destroyToken = () => {
    removeAuthToken()
    removeUser()
}

const handleServerError = (error) => {

    if (error && error.response && error.response.data && error.response.data.message) {
        return Error(error.response.data.message)
    }

    return error
}

const createRequestInterceptor = () => {

    /*
        -> Set token for closed api's
        -> Convert payload - camelCase to snake_case
    */

    axios.interceptors.request.use(config => {

        let token = getAuthToken()

        // open url list will be excluded
        if (!OPEN_URL_LIST.includes(config.url.replace(BASE_URL, '')) && token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        config.headers['Content-Type'] = 'application/json';


        // decamelize payload
        // if (config.method.toUpperCase() === 'POST' && config.data) {
        //     config.data = decamelizeKeys(config.data, { split: /(?=[A-Z0-9])/ })
        // }
        return config
    }, error => {
        return Promise.reject(error)
    })
}


const createResponseInterceptor = () => {

    /*
        -> camelize response data
        -> Save user data and token
        -> handle token time out and refresh token in background
    */
    const interceptor = axios.interceptors.response.use(({ config, data }) => {

        if (!config || !data)
            return Promise.reject(Error("Something went wrong"))

        if (data) {
            // camelize response data
            if (config.url.replace(BASE_URL, '').split('?')[0] !== 'complaintFlow') //note : to support inconsistent case from server
                data = camelizeKeys(data)
        }
        if (config.url.replace(BASE_URL, '') === AUTHENTICATE) {

            /*
               -> save token and user data in local storage
               -> delete token from data object
            */

            //TODO : Change below code according to API response
            if (data.status && data.token) {
                setAuthToken(data.token)
                // setUser(data.data.user)
                delete data.token
            }
        }
        return Promise.resolve(data)
    }, e => {

        console.error(e);

        // reject error if not 401
        // if (e.response.status !== 401) {
        //     return Promise.reject(handleServerError(e))
        // }

        if (e?.response?.status === 401) { //TODO : exclude authenticate api
            destroyToken()
            window.location = "/";
        }

        //TODO: uncomment and update 
        return Promise.reject(handleServerError(e))

        /*
            -> Eject response interceptor to avoid cyclic infinite 401
            -> Send token refresh request
            -> After sucessfull response store token and send previous request
            -> In case of error clear token and user data and navigate to login 
        */

        axios.interceptors.response.eject(interceptor);

        return axios.post(
            `${BASE_URL}silent-login`,
            null
        )
            .then((res) => {
                //TODO : Change below code according to API response
                setAuthToken(res.data.data)
                createResponseInterceptor()
                return axios(e.response.config)
            })
            .catch((voError) => {
                destroyToken()
                createResponseInterceptor()
                return Promise.reject(handleServerError(voError))
            })
    })
}

createRequestInterceptor()
createResponseInterceptor()
