import React from 'react'
import TVSLOgo from '../../assets/TVS_logo.svg';
import './splash.scss'

 const SplashLogo = () => {
  return(

    <div className='container-fluid'>
        <div className="container vh-100 d-flex justify-content-center">
            <img className='splashLogo rounded mx-auto d-block p-5' src={TVSLOgo} alt="TVS Logo"/>
        </div>
    </div>

  )
}

export default SplashLogo