import moment from "moment"

const ENABLE_LOG = process.env.REACT_APP_DEBUG

export const log = (tag, data) => {
    if (ENABLE_LOG) {
        console.log(`${tag} : `, data)
    }

}

export const toLocalePrice = (value) => {
    return Number(+value).toLocaleString('en-IN', { currency: 'INR', minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

export const toDashboardPrice = (value) => {
    return Number(+value).toLocaleString('en-IN', { currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })
}

export const toPrefixedDashboardPrice = (value) => {
    return `₹${toDashboardPrice(value)}`
}

export const toPrefixedLocalePrice = (value) => {
    return `Rs ${toLocalePrice(value)}`
}

export const toDisplayDateTime = (value) => {
    return moment(value, "YYYY-MM-DDThh:mm:ss.SSS").format('DD-MM-yyyy hh:mm A')
}

export const toDisplayDate = (value) => {
    return moment(value, "YYYY-MM-DDThh:mm:ss.SSS").format('DD-MM-yyyy')
}

export const toDisplayDateMonthTime = (value) => {
    return moment(value, "YYYY-MM-DDThh:mm:ss.SSS").format('Do MMMM YYYY, h:mm a')
}

export const toDisplayDateMonth = (value) => {
    return moment(value, "YYYY-MM-DDThh:mm:ss.SSS").format('Do MMMM YYYY')
}

export const displayOrderId = (orderId) => {
    return `#${String(orderId).padStart(6, '0')}`
}
export const toDisplayDateTimeMulLocale = (value) => {
    return moment(value, "YYYY-MM-DDThh:mm:ss.SSS").format('DD/MM/yyyy')
}

export const calendarFormat = (value) => {
    return moment(value, "YYYY-MM-DDThh:mm:ss.SSS").format('yyyy-MM-DD')
}

