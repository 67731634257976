import React, { useEffect, useState, useContext } from 'react'
import './payment.scss';
import fanImg from '../../../assets/fan_img.svg';
import { Link } from 'react-router-dom';
import UserLayout from '../../../UserLayout';
import { useLocation } from 'react-router'
import { confirmOrder } from '../../../api/API'
import { displayOrderId, toLocalePrice, toPrefixedLocalePrice } from '../../../util/Util';
import { CartContext } from '../../../CartContext';

const PaymentHistory = () => {

    const { state } = useLocation();
    const [orderDetails, setOrderDetails] = useState()
    const { cartCount, getCount } = useContext(CartContext)


    useEffect(() => {
        if (state) {
            // console.log(state);
            confirmOrder({ pgOrderId: state.orderId, pgSignature: state.paymentSg, pgPaymentId: state.paymentId })
                .then(data => {
                    setOrderDetails(data)
                    getCount();

                })
        }
    }, [])

    return (
        <UserLayout>
            {orderDetails ? (
                <div className="container-fluid  align-self-center">
                    <div className="container-fluid my-md-100 mb-md-5 px-0">
                        <div className="container px-0">
                            <div className="row justify-content-center">
                                <div className="col-md-8">
                                    <div className="ps-3">
                                        <p className="fs-l-large-semibold">Order placed!</p>
                                        <p className="fs-normal-reg">Order ID: {(orderDetails?.orderId)}</p>
                                    </div>
                                    <hr></hr>
                                    <div className='d-flex flex-row'>
                                        <p className='fs-normal-reg me-5'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="#5CA772" className="bi bi-file-earmark-text me-2" viewBox="0 0 16 16">
                                                <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
                                                <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                                            </svg>
                                            Order Summary</p>
                                    </div>
                                    {
                                        orderDetails.orderDetailList.map((product, index) => {
                                            return (
                                                <>
                                                    <div className="container px-0">
                                                        <div className="row justify-content-start align-items-center">

                                                            <div className="col-md-8 col-9">
                                                                <p className="fs-large-semibold mb-1">
                                                                    {product.title}
                                                                </p>
                                                            </div>
                                                            <div className='col col-md-8 d-flex flex-row justify-content-between'>
                                                                <p className='fs-normal-reg mb-0 me-2 mt-2'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="me-1" width="16" height="16" viewBox="0 0 24 24">
                                                                        <path fill="#5CA772" d="M19 19H5V5H19M19 3H5A2 2 0 0 0 3 5V19A2 2 0 0 0 5 21H19A2 2 0 0 0 21 19V5A2 2 0 0 0 19 3M7 9H9.31L9.63 6H11.63L11.31 9H13.31L13.63 6H15.63L15.31 9H17V11H15.1L14.9 13H17V15H14.69L14.37 18H12.37L12.69 15H10.69L10.37 18H8.37L8.69 15H7V13H8.9L9.1 11H7V9M11.1 11L10.9 13H12.9L13.1 11Z" />
                                                                    </svg>
                                                                    {product.skuCode}
                                                                </p>
                                                                <p className="mb-0">
                                                                    <span className="fs-normal-reg">
                                                                        {toPrefixedLocalePrice(product.basePrice)}
                                                                    </span>
                                                                    <span className="fs-normal-reg"> x {product.qty} pcs</span>
                                                                </p>
                                                                <p className="fs-normal-reg mb-0 mt-2">
                                                                    {toPrefixedLocalePrice(product.netAmount)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr></hr>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="container  mb-5 mt-3  d-none  d-md-block d-lg-block ">

                            <div className="row justify-content-center">
                                <div className="col-md-6 ">
                                    <div className="d-flex flex-row justify-content-between">
                                        <p className="fs-large-med"> Gross Amount:</p>
                                        <p className='fs-large-med'> {toPrefixedLocalePrice(orderDetails.grossAmount)} </p>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between">
                                        <p className="fs-large-med"> GST:</p>
                                        <p className='fs-large-med'> {toPrefixedLocalePrice(orderDetails.gstAmt)} </p>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between">
                                        <p className="fs-large-med"> Total Amount:</p>
                                        <p className='fs-large-med'> {toPrefixedLocalePrice(orderDetails.netAmount)} </p>
                                    </div>
                                    {/* <p className="fs-large-med">Delivery charges: <strong>FREE</strong></p> */}
                                    <Link className=" w-100 a-non-hover" to="/landing">
                                        <button className="btn w-100 btn-box  bg-green">

                                            <span className=" fs-normal-reg text-light" >Continue shopping</span>

                                        </button>
                                    </Link>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-sm-block d-md-none d-lg-none">
                        <div className="row align-self-center justify-content-center py-2 w-100 sticky-div"  >
                            <div className="col-10 align-self-center text-center justify-content-center">
                                <div className="d-flex flex-row justify-content-between">
                                    <p className="fs-large-med"> Gross Amount:</p>
                                    <p className='fs-large-med'> {toPrefixedLocalePrice(orderDetails.grossAmount)} </p>
                                </div>
                                <div className="d-flex flex-row justify-content-between">
                                    <p className="fs-large-med"> GST:</p>
                                    <p className='fs-large-med'> {toPrefixedLocalePrice(orderDetails.gstAmt)} </p>
                                </div>
                                <div className="d-flex flex-row justify-content-between">
                                    <p className="fs-large-med"> Total Amount:</p>
                                    <p className='fs-large-med'> {toPrefixedLocalePrice(orderDetails.netAmount)} </p>
                                </div>
                                {/* <p className="fs-large-med mb-1">Delivery charges: <strong>FREE</strong></p> */}
                                <Link className=" w-100 a-non-hover" to="/">
                                    <button className="btn w-100 btn-box  bg-green">
                                        <span className=" fs-normal-reg text-light" >Continue shopping</span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>) : (<></>)}
        </UserLayout>
    )
}
export default PaymentHistory