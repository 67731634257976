import React, { useEffect } from 'react'
import './account.scss';
import UserLayout from '../../../UserLayout';
import { getAccountStatement } from '../../../api/API';
import { useState } from 'react';
import { displayOrderId, toDisplayDateTime, toLocalePrice, toPrefixedLocalePrice } from '../../../util/Util';
import { Link } from 'react-router-dom';


const Account = () => {

    const [accountStatement, setAccountStatement] = useState()
    useEffect(() => {
        getAccountStatement()
            .then(data => {
                setAccountStatement(data)
                console.log(data)
            })
    }, [])

    return (
        <UserLayout>
            <div className="container-fluid   align-self-center">
                <div className="row justify-content-center">
                    <div className="container my-md-200 ">
                        <div className="row justify-content-center">
                            <div className="col-md-7 col-11 px-0">
                                <div className="mb-3 d-lg-block d-md-block d-none">
                                    <div className="d-flex flex-row ">
                                        <Link className="d-flex flex-row  a-nonlink" to="/landing">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#5CA772" className="bi bi-chevron-left mt-1" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                            </svg>
                                            <p className="fs-large-med btn-link text-green ms-2">Home</p>
                                        </Link>
                                        <p className="fs-large-light text-dark ms-2">/ Account Statement</p>
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <p className="fs-large-regular text-green mb-2">Account summary</p>
                                    <p className="fs-large-med mb-2">Total : <strong> {toPrefixedLocalePrice(accountStatement?.total)}</strong></p>
                                    <hr></hr>
                                </div>
                                <p className="fs-large-reg text-green">DETAILS</p>
                                <div className='col d-flex flex-row justify-content-between'>
                                    <p className='col-3 fs-large-med'>Order No</p>
                                    <p className='col-4 fs-large-med'>Date</p>
                                    <p className='col-2 fs-large-med text-center'>Amount</p>
                                </div>
                                {
                                    accountStatement && accountStatement.tranLog && accountStatement.tranLog.length > 0 ? accountStatement.tranLog.map((tran, key) => {
                                        return (
                                            <div>
                                                <div key={key} className='col-12 col-md-12 d-flex flex-cloumn justify-content-between'>
                                                    <div className="col-4 col-md-4">
                                                        <p className="fs-normal-med">
                                                            {tran.orderId}
                                                        </p>
                                                    </div>
                                                    <div className='col-4 col-md-4'>
                                                        <p className="fs-normal-med mb-1 mt-1 ">
                                                            {toDisplayDateTime(tran.createdAt)}
                                                        </p>
                                                    </div>
                                                    <div className ='col-4 col-md-4'>
                                                        <p className="fs-normal-med mb-1 text-end">
                                                            {toPrefixedLocalePrice(tran.netAmount)}
                                                        </p>
                                                    </div>
                                                {/* <div className="d-flex flex-column"> */}
                                                    {/* <p className="fs-small-light mb-1">
                                                        {tran.pgPaymentId}
                                                    </p> */}
                                                {/* </div> */}
                                                </div>
                                                <hr></hr>
                                            </div>
                                        )
                                    }) : (<></>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </UserLayout>
    )
}
export default Account