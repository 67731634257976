import React, { useEffect, useState } from 'react'
import avatarImg from '../../../assets/avatar.png';
import './profile.scss';
import { Link } from "react-router-dom";
import UserLayout from '../../../UserLayout';
import { RiLogoutBoxFill } from 'react-icons/ri';
import { getUser, removeAuthToken, getUserPartner } from '../../../util/LocalStorageUtil';
import { useNavigate } from 'react-router'

const Profile = () => {

    const navigate = useNavigate()
    const [user, setUser] = useState()
    const [partner, setPartner] = useState()

    const logOut = () => {
        // console.log(user)
        removeAuthToken();
        navigate('/login');
    }

    useEffect(() => {
        setUser(getUser())
        setPartner(getUserPartner())
        // console.log(user)
    }, [])

    return (
        <UserLayout>
            <div className="container-fluid   align-self-center">
                <div className="row justify-content-center">
                    <div className="container my-md-200">
                        {/* <div className="row text-center">
                        <a href="/change-password" className="a-nonlink">
                            <span className=" fs-normal-reg text-green" >Profile Page</span></a>
                    </div> */}
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <div className="d-none d-lg-block d-md-block mb-3">
                                    <div className="d-flex flex-row ">
                                        <Link className="d-flex flex-row  a-nonlink" to="/landing">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#5CA772" className="bi bi-chevron-left mt-1" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                            </svg>
                                            <p className="fs-large-med btn-link text-green ms-2">Home</p>
                                        </Link>
                                        <p className="fs-large-light text-dark ms-2">/ Profile</p>
                                    </div>
                                </div>
                                <div className="card bg-transparent b-none" id="scard">
                                    <div className="card-body">
                                        <div className="row justify-content-center">
                                            <div className="col-md-11 mb-5 d-flex flex-column justify-content-center align-items-center ">
                                                <img src={avatarImg} alt="Avatar" className="avatar" />
                                                <p className="fs-large-regular">
                                                        {user?.fullName}
                                                </p>
                                                <div className='col d-flex flex-row'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone text-green mt-1 me-2" viewBox="0 0 16 16">
                                                        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                                                    </svg>
                                                    <p className="fs-large-regular">{user?.mobile}</p>
                                                </div>
                                                <div className='col d-flex flex-row'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope text-green mt-1 me-2" viewBox="0 0 16 16">
                                                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                                                    </svg>
                                                    <p className="fs-large-regular">{user?.email}</p>
                                                </div>
                                                <div className='col '>
                                                    <p className="fs-large-regular">{partner?.contactPerson}</p>
                                                </div>
                                                <div className='col'>
                                                    <p className="fs-large-regular">{partner?.partName}</p>
                                                </div>
                                                <div className='col '>
                                                    <p className="fs-large-regular">{partner?.mobile1}</p>
                                                </div>
                                                <div className='col d-flex flex-column text-center'>
                                                    <p className="fs-large-regular m-0">{partner?.address1}</p>
                                                    <p className="fs-large-regular m-0">{partner?.address2}</p>
                                                    <p className="fs-large-regular m-0">{partner?.address3}</p>
                                                </div>
                                            </div>
                                            {/* <div className="col-md-11 my-auto">
                                                <Link to="/change-password" className="a-nonlink m-0 p-0">
                                                    <div className=" my-2 d-flex flex-row justify-content-between list-group-flush">
                                                        <li className="fs-l-large-regular ">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                                                                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                                            </svg>
                                                            <span className="ms-2">Change Password</span>
                                                        </li>
                                                        <div className="my-auto">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <hr></hr> */}
                                           
                                            {/* <div className="col-md-11 my-auto">
                                                <button onClick={() => { logOut() }} className="a-nonlink m-0 p-0 w-100 bg-transparent border-0"><div className=" my-2 d-flex flex-row justify-content-between list-group-flush">
                                                    <li className="fs-l-large-regular  "><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                                                        <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                                                    </svg><span className="ms-2">Logout</span></li>
                                                    <div className="my-auto"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                                    </svg></div>
                                                </div></button>
                                            </div> */}
                                            <div className="sticky-div d-block d-lg-none d-md-none">
                                                <button onClick={()=>{logOut()}} className="col btn w-100 custom-btn-user btn-outline-danger py-4 my-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-power" viewBox="0 0 16 16">
                                                        <path d="M7.5 1v7h1V1h-1z"/>
                                                        <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z"/>
                                                    </svg>
                                                    <span className="fs-l-large-semibold text-danger ps-3" >Sign Out</span>
                                                </button>
                                            </div>

                                            <div className="fixed-bottom w-50 d-lg-block d-md-block d-none">
                                                <button onClick={()=>{logOut()}} className="col btn w-100 custom-btn-user btn-outline-danger py-4 my-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-power" viewBox="0 0 16 16">
                                                        <path d="M7.5 1v7h1V1h-1z"/>
                                                        <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z"/>
                                                    </svg>
                                                    <span className="fs-l-large-semibold text-danger ps-3" >Sign Out</span>
                                                </button>
                                            </div>

                                        </div>


                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </UserLayout>
    )
}
export default Profile