import React, { useEffect, useState } from 'react'
import './feedback.scss';
import { Modal } from 'bootstrap'
import Pagination from '@mui/material/Pagination';
import AdminLayout from '../../../AdminLayout';
import SearchIcon from "@mui/icons-material/SearchOutlined";
import { CustomOptions } from '../../user/login/Login';
import { useSnackbar } from 'react-simple-snackbar';
import { getFeedBackDetails, getOrderList } from '../../../api/API';
import AddInventory from './ViewFeedback';
import ViewFeedback from './ViewFeedback';
import { toDisplayDateMonthTime } from '../../../util/Util';
import { ShimmerFeed } from '../../../components/shimmer-effect/ShimmerEffect';
import { Stack } from '@mui/material';
import { PAGINATION_ITEM_PER_PAGE_FEED } from '../../../util/EnvHelper';


const FeedbackList = () => {


    const [isLoading, setIsLoading] = React.useState(true);
    const [openSnackbar] = useSnackbar(CustomOptions)
    const [showPIndex, setShowPIndex] = useState(-1)
    const [state, setState] = React.useState({
        feedList: [], noOfPages: 0, 
    });
    const itemsPerPage = PAGINATION_ITEM_PER_PAGE_FEED;
    const [page, setPage] = React.useState(1);
    const [filterList, setFilterList] = useState([state.feedList]);

    useEffect(() => {
        getFeedList()
    }, [])


    const getFeedList = async () => {
        getFeedBackDetails()
            .then((data) => {
                if (data) {
                    setState(
                        {
                            ...state,
                            feedList: data,
                            noOfPages: Math.ceil(data.length / itemsPerPage)

                        })
                        setFilterList(data)
                }
                setIsLoading(false)
            }).catch(e => {
                setIsLoading(false)
                openSnackbar(e)
            })
    };

    useEffect(() => {
        if (showPIndex > -1) {
            const modalFeed = new Modal(document.querySelector('#feedbackViewModal'), {
                keyboard: false
            })
            modalFeed.show()
        }
    }, [showPIndex])

    const setFeedBackData = (index) => {
        setShowPIndex(index)
    }

    const ViewModal = () => {
        return showPIndex > -1 ? (<ViewFeedback feedbackDetails={state.feedList[showPIndex]} onModalClose={() => setFeedBackData(-1)} />) : (<></>)
    }

    // const viewFeedData = async (feedDetails, itemId) => {
    //     setState({
    //         ...state,
    //         feedbackDetails: feedDetails, feedId: itemId
    //     });

    //     const modalFeed = new Modal(document.querySelector('#feedbackViewModal'), {
    //         keyboard: false
    //     })
    //     modalFeed.show()
    // }
    const onPageChange = (event, value) => {
        setPage(value);
    };

    const searchFliter = (e) => {
        setPage(1);
        let searchText = e.target.value.toUpperCase();
        let searchResult = [];
     
        searchResult = (state.feedList).filter((item) => {
            const feedback = item.feedback ? item.feedback.toUpperCase() : ''.toUpperCase();
            const contactPerson = item.contactPerson ? item.contactPerson.toUpperCase() : ''.toUpperCase();

            return (feedback.search(searchText) != -1 || contactPerson.search(searchText) != -1)
          
        });
        setFilterList(searchResult);

    }

    return (
        <AdminLayout title="Feedback">
            <ViewModal />
            <div className="container-fluid align-self-center">
                <div className="row justify-content-start">

                    <div className="col-md-8 d-flex flex-row justify-content-between">
                        <div className="search-input-btn d-flex flex-row justify-content-between border-0 rounded-bg px-3 col-md-8">

                            <div className="my-auto">
                                <SearchIcon />
                            </div>
                            <input type="text" className="form-control border-0 bg-transparent fs-large-regular "
                                onChange={(e) => { searchFliter(e) }} placeholder="Search for anything " />
                        </div>


                    </div>

                    {isLoading ? (<ShimmerFeed />) :
                       filterList !== null ?
                       filterList
                                .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                                .map((feed, index) => {
                                    return (
                                        <div className=" col-md-12 p-3 table-container mt-3 " key={filterList.indexOf(feed)}>
                                            <div className="row justify-content-between" >
                                                <div className="col-md-10">
                                                    <p className="fs-large-med">{feed.feedback}</p>
                                                </div>
                                                <div className="col-md-2 text-end">
                                                    <button className="action-circle me-2" onClick={() => setFeedBackData(state.feedList.indexOf(feed))}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye text-green mb-4p" viewBox="0 0 16 16">
                                                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                                        </svg>
                                                    </button>
                                                </div>
                                                <div className="col-">
                                                    <div>
                                                        <span className=" me-3 fs-normal-light">{feed.contactPerson}</span>
                                                        <span className="fs-normal-light">{toDisplayDateMonthTime(feed.createdAt)}</span>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                    );

                                }) :
                            <div className=" col-md-12 p-3 table-container mt-3 " >
                                <div className="row justify-content-center" >
                                    <div className="col-md-12 text-center">
                                        <h1 className="fs-large-regular"><i>No records</i></h1>
                                    </div></div></div>
                    }
                    <div className="col-md-12 mt-5 justify-content-center text-center">
                        <Stack spacing={2} alignItems="center">
                            <Pagination
                                count={Math.ceil((filterList).length / itemsPerPage)}
                                page={page}
                                onChange={onPageChange}
                                defaultPage={1}
                                size="large"
                                variant="outlined" color="success"
                                showFirstButton
                                showLastButton
                            />
                        </Stack>
                    </div>
                    {/* <div className=" col-md-7 p-3 table-container mt-3">
                        <table className="table table-striped table-borderless  ">
                            <thead>
                                <tr className="fs-large-light">
                                    <td scope="col" >
                                        <div className="d-flex flex-row my-auto">
                                            <div className="my-auto ">
                                                <p className="me-2 mb-0 ws-no-wrap">Customer Name</p>
                                            </div>

                                            <div className="d-flex flex-column my-auto">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-caret-up text-grey " viewBox="0 0 16 16" >
                                                    <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-caret-down text-grey" viewBox="0 0 16 16">
                                                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="col">
                                        <div className="d-flex flex-row my-auto">
                                            <div className="my-auto">
                                                <p className="me-2 mb-0 ws-no-wrap">Mobile number</p>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-caret-up text-grey " viewBox="0 0 16 16" >
                                                    <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-caret-down text-grey" viewBox="0 0 16 16">
                                                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="col" >
                                        <div className="d-flex flex-row my-auto">
                                            <div className="my-auto">
                                                <p className="me-2 mb-0">Email address</p>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-caret-up text-grey " viewBox="0 0 16 16" >
                                                    <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-caret-down text-grey" viewBox="0 0 16 16">
                                                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </td>

                                    <td scope="col " >
                                        <div className=" d-flex flex-row my-auto">
                                            <div className="my-auto">
                                                <p className="me-2 mb-0"></p>
                                            </div>

                                        </div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    feedList.map((feed, index) => {
                                        return (
                                            <tr className="align-middle fs-large-med" key={index}>
                                                <td scope="row">{feed.name}</td>
                                                <td>{feed.contact}</td>
                                                <td>
                                                    {feed.email} <br />
                                                </td>
                                                <td>
                                                    <div className="d-flex flex-row justify-content-center">
                                                        <button className="action-circle me-2" onClick={() => viewFeedData(feed, feed.feedId)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye text-green" viewBox="0 0 16 16">
                                                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                                            </svg>
                                                        </button>

                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })

                                }
                            </tbody>
                        </table>

                        <div className=" mt-5 justify-content-center text-center custom-pagination">
                            <Pagination count={10} size="large" />
                        </div>
                    </div> */}
                </div>
            </div>

            {/* Vendor view and edit modal */}

            {/* Vendor view and edit modal */}

        </AdminLayout>
    )

}
export default FeedbackList