import React , {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import './landing.scss';
import UserLayout from '../../../UserLayout';
import { getUser, removeAuthToken } from '../../../util/LocalStorageUtil';
import { useNavigate } from 'react-router'
import {getPartnerAnnouncement} from '../../../api/API'
import {getAnnouncementStatus,setAnnoucementFlag} from '../../../util/LocalStorageUtil'


const Landing = () => {

  const [announcement,setAnnouncement] = useState([])
  const navigate = useNavigate()
  const [anFlag,setAnFlag] = useState(getAnnouncementStatus())

  useEffect(() => {
    getAnnouncementDesc()
    // console.log(anFlag.anId, anFlag.flag);
},[anFlag])
  
  const logOut = () => {
    removeAuthToken();
    navigate('/login');
}

const getAnnouncementDesc = () => {
  getPartnerAnnouncement()
  .then(data => {
      setAnnouncement(data)
  }).catch(e => {
  })
}

const handleCloseAnnouncement = () => {
  setAnnoucementFlag({anId:anFlag.anId,flag:1})
  setAnFlag(getAnnouncementStatus())
}

  return (
      <UserLayout>
        <div className="container my-md-100 d-block mx-lg-auto">
        {(announcement!== null || (announcement.description !== null && announcement.description !== "")) && anFlag?.flag!==1 ? 
                        <div className="card h-100 rounded-card p-4 mb-3 mt-3">
                          
                            <div className='col-md-12 d-flex flex-row justify-content-between'>
                                <div className='col-md-8 d-flex flex-row'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-megaphone me-3 text-green" viewBox="0 0 16 16">
                                        <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49a68.14 68.14 0 0 0-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 74.663 74.663 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199V2.5zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0zm-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233c.18.01.359.022.537.036 2.568.189 5.093.744 7.463 1.993V3.85zm-9 6.215v-4.13a95.09 95.09 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A60.49 60.49 0 0 1 4 10.065zm-.657.975 1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68.019 68.019 0 0 0-1.722-.082z"/>
                                    </svg>
                                    <p className='fs-large-semibold text-green'>Announcements</p>
                                </div>
                                <svg   onClick={() => handleCloseAnnouncement()}xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-x-circle text-danger pointer" viewBox="0 0 16 16">
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>  
                            </div>
                            <p className='fs-normal-reg text-dark'>{announcement.description}</p>
                        </div> 
                        : null
            }
          <div className="row row-cols-lg-2">
        <Link to="/" className="a-nonlink">
            <button className="col btn w-100 btn-box btn-outline-success py-4 my-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-compass-fill" viewBox="0 0 16 16">
                    <path d="M15.5 8.516a7.5 7.5 0 1 1-9.462-7.24A1 1 0 0 1 7 0h2a1 1 0 0 1 .962 1.276 7.503 7.503 0 0 1 5.538 7.24zm-3.61-3.905L6.94 7.439 4.11 12.39l4.95-2.828 2.828-4.95z"/>
                </svg>
              <span className="fs-l-large-semibold text-green ps-3" >Browse Products</span>
            </button>
        </Link>
        <div>
            <a target="_blank" rel="noreferrer" href="https://api.tvsgreen.in/documents/catalogue.pdf">
                <button className="col btn w-100 btn-box btn-outline-success py-4 my-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                    </svg>
                <span className="fs-l-large-semibold text-green ps-3" >Download Catalogue</span>
                </button>
            </a>
        </div>
          <Link to="/place-order" className='a-nonlink'>
            <button className="col btn w-100 btn-box btn-outline-success py-4 my-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-cart-plus" viewBox="0 0 16 16">
                    <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9V5.5z"/>
                    <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                </svg>
              <span className="fs-l-large-semibold text-green ps-3" >Place order</span>
            </button>
          </Link>

          <Link to='/order' className='a-nonlink'>
            <button className="col btn w-100 btn-box btn-outline-success py-4 my-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-cart3" viewBox="0 0 16 16">
                    <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </svg>
              <span className="fs-l-large-semibold text-green ps-3" >Order Status</span>
            </button>
          </Link>

          <Link to="/account" className='a-nonlink'>
            <button className="col btn w-100 btn-box btn-outline-success py-4 my-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
                    <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/>
                    <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
                </svg>
              <span className="fs-l-large-semibold text-green ps-3" >Account Statement</span>
            </button>
          </Link>

          <Link to="/query-list" className='a-nonlink'>
            <button className="col btn w-100 btn-box btn-outline-success py-4 my-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-wrench" viewBox="0 0 16 16">
                    <path d="M.102 2.223A3.004 3.004 0 0 0 3.78 5.897l6.341 6.252A3.003 3.003 0 0 0 13 16a3 3 0 1 0-.851-5.878L5.897 3.781A3.004 3.004 0 0 0 2.223.1l2.141 2.142L4 4l-1.757.364L.102 2.223zm13.37 9.019.528.026.287.445.445.287.026.529L15 13l-.242.471-.026.529-.445.287-.287.445-.529.026L13 15l-.471-.242-.529-.026-.287-.445-.445-.287-.026-.529L11 13l.242-.471.026-.529.445-.287.287-.445.529-.026L13 11l.471.242z"/>
                </svg>
              <span className="fs-l-large-semibold text-green ps-3" >Replace/ Warranty</span>
            </button>
          </Link>

          <Link to='/contact' className='a-nonlink'>
            <button className="col btn w-100 btn-box btn-outline-success py-4 my-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-chat-square-quote" viewBox="0 0 16 16">
                    <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                    <path d="M7.066 4.76A1.665 1.665 0 0 0 4 5.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 1 0 .6.58c1.486-1.54 1.293-3.214.682-4.112zm4 0A1.665 1.665 0 0 0 8 5.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 1 0 .6.58c1.486-1.54 1.293-3.214.682-4.112z"/>
                </svg>
              <span className="fs-l-large-semibold text-green ps-3" >Contact/ Feedback</span>
            </button>
          </Link>
          
          <div>
            <button onClick={()=>{logOut()}} className="col btn w-100 btn-outline-danger custom-btn-user py-4 my-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-power" viewBox="0 0 16 16">
                    <path d="M7.5 1v7h1V1h-1z"/>
                    <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z"/>
                </svg>
              <span className="fs-l-large-semibold custom-btn text-danger ps-3" >Sign Out</span>
            </button>
          </div>
          
        </div>
        </div>
      </UserLayout>
    
  )
}
export default Landing