import React from 'react'

const LoadingButton = ({
    buttonText = "Submit", btnClass = 'btn-primary br-21 p-07rem mb-3 w-100 text-light fs-large-semibold', isLoading = false, padding = '', loadingText = 'loading...',onSubmit,onClick, width = '' }) => {
    return (
        <button
            type="submit"
            onClick={onClick}
            onSubmit={onSubmit}
            className={`btn ${btnClass} ${width} ${padding}`}
            disabled={isLoading}
        >
            {
                isLoading ? (
                    <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                    >
                    </span>
                ) : (<></>)
            }
            {isLoading ? loadingText : buttonText}
        </button >
    )
}

export default LoadingButton