import React, { useState,useEffect } from 'react'
import './login.scss'
import LoadingButton from '../../../components/loading-button/LoadingButton'
import { useNavigate } from 'react-router'
import { login, sendOtp } from '../../../api/API'
import { useSnackbar } from 'react-simple-snackbar'
import backGround from '../../../assets/banner1.png'
import { Link } from 'react-router-dom'
import { USER_TYPE } from "../../../util/EnvHelper";
import SplashLogo from '../../../components/splash/SplashLogo';

export const CustomOptions = {
    position: 'bottom-center',
    style: {
        backgroundColor: 'white',
        border: '1px solid white',
        color: '#A75C5C',
        fontSize: '14px',
        textAlign: 'center'
    },
    closeStyle: {
        color: 'black',
        fontSize: '12px',
    },

}
const Login = () => {

    const [phoneNum, setPhoneNum] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [splashLoad,setSplashLoad] = useState(true)
    const navigate = useNavigate();
    const [openSnackbar] = useSnackbar(CustomOptions)


    const gotHomePage = () => {
        // if (!phoneNum || !(phoneNum.match('[0-9]{10}')) || !(phoneNum.length == 10)) {
        //     openSnackbar("Enter valid mobile number!")
        //     return
        // }
        setIsLoading(true)

        login({ username: phoneNum, userType: USER_TYPE })
            .then((status) => {
                // console.log(0)
                // console.log("status:  "+status)
                if (status === 1) {
                    setIsLoading(false)
                    navigate('/password')
                }
                else if (Number(status) === 0) {
                    sendOTP(status)

                }
                else {
                    setIsLoading(false)
                    openSnackbar('Something went wrong!')
                }
            }).catch(e => {
                setIsLoading(false)
                openSnackbar(e)
            })
    }


    const sendOTP = (status) => {
        const userStatus = status
        // console.log(userStatus,status)
        sendOtp({mobileOrEmail:phoneNum, userType: USER_TYPE })
            .then((data) => {
                setIsLoading(false)
                if (data) {
                    // console.log({data})
                    navigate('/forgot-password' , { state: data })
                }
                else {
                    openSnackbar('Something went wrong!')
                }
            }).catch(e => {
                setIsLoading(false)
                openSnackbar(e)
            })
    }

    const splash = () => {
        setSplashLoad(false)
    }

    useEffect(() => {
        setTimeout(splash,2900)
    },[])

    return (
        <>
        {splashLoad?<SplashLogo/>:
        <>
        <div className='loginBody'>
            <img className="banner w-100" src={backGround} alt="banner" />
            <div className="container  mt-md-50 ">
                <div className="row justify-content-center">
                    <div className="col-md-4 p-2rem my-auto login-container col-11">
                        <h1 className="text-left  pb-4 fs-l-large-regular">Login</h1>
                        <form onSubmit={(e) => {e.preventDefault()}}>
                            <input type="text"
                                className="form-control login-input p-07rem mb-4 w-100 fs-x-large-regular px-4"
                                onChange={(e) => setPhoneNum(e.target.value)} onSubmit={() => gotHomePage()} placeholder="Email or Mobile Number " autoFocus/>
                                
                            {/* <Link to="/otp" type="btn" className="btn bg-green btn-focus br-21 p-07rem mb-3 w-100 text-light fs-large-semibold">LOGIN</Link> */}

                            <LoadingButton
                                buttonText="LOGIN"
                                loadingText="LOGGING IN..."
                                width="w-100"
                                onClick={gotHomePage}
                                onSubmit={() => gotHomePage()} 
                                isLoading={isLoading}
                            />
                        </form>
                    </div>
                </div>
            </div>
            
        </div>
        <nav className="navbar fixed-bottom bg-dark mt-5 p-3">
            <div className="container-fluid">
                <a className="fs-normal-med text-white" target="_blank" rel="noreferrer" href="http://tvsgreen.in/#about">About Us</a>
                <a className="fs-normal-med text-white" target="_blank" rel="noreferrer" href="http://tvsgreen.in/#contact">Contact Us</a>
                <a className="fs-normal-med text-white" target="_blank" rel="noreferrer" href="/terms-conditions">Terms & Conditions</a>
                <a className="fs-normal-med text-white" target="_blank" rel="noreferrer" href="/return-policy">Return Policy</a>
                <a className="fs-normal-med text-white" target="_blank" rel="noreferrer" href="/privacy-policy">Privacy Policy</a>
                <a className="fs-normal-med text-white" target="_blank" rel="noreferrer" href="/shipping-policy">Shipping Policy</a>
            </div>
        </nav>
        </>
    }
    </>
    )
}
export default Login