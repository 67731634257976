
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import AdminLogin from "./pages/admin/login/Login";
import SnackbarProvider from 'react-simple-snackbar'
import { getAdminAuthtoken, getAuthToken, getUser, getUserStatus } from './util/LocalStorageUtil'
import Dashboard from "./pages/admin/dashboard/Dashboard";
import VendorList from "./pages/admin/vendors/VendorList";
import UserOrderList from "./pages/admin/order/Order";
import ForgotPassword from "./pages/admin/login/ForgotPassword";
import InventoryList from "./pages/admin/inventory/Inventory";
import UserQueryList from "./pages/admin/query/Query";
import SalesHeirarchy from "./pages/admin/heirarchy/SalesHeirarchy"

import UserLogin from "./pages/user/login/Login";
import Otp from "./pages/user/login/Otp";
import UserForgotPassword from "./pages/user/login/ForgotPassword";
import Home from "./pages/user/home/Home";
import Product from "./pages/user/product/Product";
import Cart from "./pages/user/cart/Cart";
import Payment from "./pages/user/payment/Payment";
import PaymentHistory from "./pages/user/payment/PaymentHistory";
import ChangePassword from "./pages/user/profile/ChangePassword";
import Order from "./pages/user/order/Order";
import Profile from "./pages/user/profile/Profile";
import SearchResult from "./pages/user/search/SearchResult";
import Search from "./pages/user/search/Search";
import Account from "./pages/user/account/Account";
import QueryList from "./pages/user/query/QueryList";


import QueryDetails from "./pages/user/query/QueryDetails";
import SearchQuery from "./pages/user/query/SearchQuery";
import SearchQueryResult from "./pages/user/query/SearchQueryResult";
import Magnify from "./pages/user/magnify";
import Test from "./pages/user/Test";
import Password from "./pages/user/login/Password";
import Rechart from "./pages/admin/dashboard/Recharts";
import Landing from "./pages/user/landing/Landing";
import Contact from "./pages/user/contact/Contact"
import PlaceOrder from "./pages/user/order/PlaceOrder";
import FilterModal from "./pages/user/order/FilterModal"
import { CartProvider } from "./CartContext";
import FeedbackList from "./pages/admin/feedback/Feedback";
import Announcement from "./pages/admin/announcement/Announcement";
import Report from "./pages/admin/report/Report";
import { ADMIN_TYPE, USER_TYPE } from "./util/EnvHelper";
import PrivacyPolicy from "./pages/user/policies/PrivacyPolicy";
import ReturnPolicy from "./pages/user/policies/ReturnPolicy";
import ShippingPolicy from "./pages/user/policies/ShippingPolicy";
import TermsConditions from "./pages/user/policies/TermsConditions";



function App() {

  // const userType = getUser()
  // console.log(userType?.userType)
  //user navigation
  const ProtectedPage = ({ children }) => {
    const isLoggedIn = getAuthToken()
    const userType = getUser()
    return isLoggedIn ? (userType?.userType !== ADMIN_TYPE ? children : <Navigate to="/admin/login" />) : <Navigate to="/login" />
  }
  const Onboading = ({ children }) => {
    const isLoggedIn = getAuthToken()
    return isLoggedIn ? <Navigate to="/" /> : children
  }
  const CheckLogin = ({ children }) => {
    const isLoggedIn = getAuthToken()
    const { pathname } = useLocation()
    return isLoggedIn && pathname === '/login' ? <Navigate to="/" /> : children
  }

  //dashboard navigation
  const CheckAuthLogin = ({ children }) => {
    const isLoggedIn = getAuthToken()
    const { pathname } = useLocation()
    return isLoggedIn && pathname === '/admin/login' ? <Navigate to="/dashboard" /> : children
  }

  const AuthPage = ({ children }) => {
    const isLoggedIn = getAuthToken()
    const userType = getUser()
    return isLoggedIn ? (userType?.userType !== USER_TYPE ? children : <Navigate to="/login" />) : <Navigate to="/admin/login" />
  }
  return (

    <SnackbarProvider>
      <CartProvider>
        <Router>
          {/* <Route exact path="/">
        TODO: if logged in navigate to home else login
        <Redirect to="/login" />
      </Route> */}
          <div className="app">
            <Routes>
              <Route path="/login" element={<CheckLogin><UserLogin /></CheckLogin>} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/return-policy" element={<ReturnPolicy />} />
              <Route path="/shipping-policy" element={<ShippingPolicy />} />
              <Route path="/terms-conditions" element={<TermsConditions />} />
              <Route path="/password" element={<Onboading><Password /></Onboading>} />
              <Route path="/otp" element={<Otp />} />
              <Route path="/forgot-password" element={<Onboading><UserForgotPassword /></Onboading>} />

              <Route path="/" element={<ProtectedPage><Home /></ProtectedPage>} />
              <Route path="/landing" element={<ProtectedPage><Landing /></ProtectedPage>} />
              <Route path="/place-order" element={<ProtectedPage><PlaceOrder /></ProtectedPage>} />
              <Route path="/product" element={<ProtectedPage><Product /></ProtectedPage>} >
                <Route path=":pageId" element={<ProtectedPage><Product /></ProtectedPage>} />
              </Route>
              <Route path="/cart" element={<ProtectedPage><Cart /></ProtectedPage>} />
              <Route path="/payment" element={<ProtectedPage><Payment /></ProtectedPage>} />
              <Route path="/pay-history" element={<ProtectedPage><PaymentHistory /></ProtectedPage>} />
              <Route path="/change-password" element={<ProtectedPage><ChangePassword /></ProtectedPage>} />
              <Route path="/order" element={<ProtectedPage><Order /></ProtectedPage>} />
              <Route path="/profile" element={<ProtectedPage><Profile /></ProtectedPage>} />
              <Route path="/search" element={<ProtectedPage><Search /></ProtectedPage>} />
              <Route path="/s-result" element={<ProtectedPage><SearchResult /></ProtectedPage>} />
              <Route path="/account" element={<ProtectedPage><Account /></ProtectedPage>} />
              <Route path="/contact" element={<ProtectedPage><Contact /></ProtectedPage>} />
              <Route path="/query-list" element={<ProtectedPage><QueryList /></ProtectedPage>} />
              <Route path="/query-details" element={<ProtectedPage><QueryDetails /></ProtectedPage>} />
              <Route path="/search-query" element={<ProtectedPage><SearchQuery /></ProtectedPage>} />
              <Route path="/search-query-result" element={<ProtectedPage><SearchQueryResult /></ProtectedPage>} />

              <Route path="/magnify" element={<Magnify />} />
              <Route path="/test" element={<Test />} />
            </Routes>
          </div>

          <div className="admin-panel">
            <Routes>
              <Route path="/admin/login" element={<CheckAuthLogin><AdminLogin /></CheckAuthLogin>} />
              <Route path="/admin-forgot-password" element={<ForgotPassword />} />
              <Route path="/dashboard" element={<AuthPage><Dashboard /></AuthPage>} />
              <Route path="/vendor" element={<AuthPage><VendorList /></AuthPage>} />
              <Route path="/order-list" element={<AuthPage><UserOrderList /></AuthPage>} />
              <Route path="/recharts" element={<Rechart />} />
              <Route path="/product-list" element={<AuthPage><InventoryList /></AuthPage>} />
              <Route path="/feedback-list" element={<AuthPage><FeedbackList /></AuthPage>} />
              <Route path="/user-query-list" element={<AuthPage><UserQueryList /></AuthPage>} />
              <Route path="/announcement" element={<AuthPage><Announcement /></AuthPage>} />
              <Route path="/report-list" element={<AuthPage><Report /></AuthPage>} />
              <Route path="/sales-heirarchy" element={<AuthPage><SalesHeirarchy /></AuthPage>} />

            </Routes>
          </div>
        </Router>
      </CartProvider>
    </SnackbarProvider>

  );
}

export default App;

