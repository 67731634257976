import React, { useEffect, useState } from "react";
import { getComplaintListDetails } from "../../../api/API";
import { ShimmerOrdersDetailTable } from "../../../components/shimmer-effect/ShimmerEffect";
import { toDisplayDateTimeMulLocale } from "../../../util/Util";
import './query.scss';
import { CustomOptions } from '../../user/login/Login';
import { useSnackbar } from 'react-simple-snackbar';

const ViewQuery = ({ queryDetails, compId, onModalClose }) => {

    const [queryListDetails, setQueryListDetails] = useState(queryDetails)
    const [complaintListDetails, setcomplaintListDetails] = useState([])
    const [openSnackbar] = useSnackbar(CustomOptions)


    useEffect(() => {
        getCompListDetails(compId)
        setQueryListDetails(queryDetails)

    })

    useEffect(() => {
        window.onpopstate = e => {
            window.location.reload()
        }
    })
    const getCompListDetails = async (compId) => {
        getComplaintListDetails(compId)
            .then((data) => {
                if (data) {
                    setcomplaintListDetails(data)
                }
            }).catch(e => {
                openSnackbar(e)
            })
    };


    return (
        queryListDetails ? (

            <div className="modal fade custom-modal" id="queryViewModal" tabIndex="-1" aria-labelledby="vendorEditAddModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg max-w-600 modal-dialog-centered  modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header" style={{ borderBottom: 0 }}>

                            <h1 className="fs-normal-semibold text-primary my-auto">Query</h1>

                            <button type="button" className=" my-auto custom-btn" data-bs-dismiss="modal" aria-label="Close" onClick={onModalClose}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-circle float-end text-primary" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </button>

                        </div>
                        <div className="modal-body px-0">
                            <div className="container  pt-0 vendor-modal-body">
                                <div className="row ">


                                    <div className="col-md-6">
                                        <label className="fs-normal-reg mb-2">Customer name</label>
                                        <input
                                            type="text"
                                            className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                            disabled={true}
                                            name="name" value={queryListDetails.contactPerson}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="fs-normal-reg mb-2">Submitted on</label>
                                        <input
                                            type="text"
                                            className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"

                                            disabled={true}
                                            name="contact" value={toDisplayDateTimeMulLocale(queryListDetails.dateTime)}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="fs-normal-reg mb-2">No of items</label>
                                        <input
                                            type="text"
                                            className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                            disabled={true}
                                            name="items" value={complaintListDetails !== null && complaintListDetails !== undefined ? complaintListDetails.length : 0}
                                        />
                                    </div>


                                </div>
                                {complaintListDetails ?
                                    complaintListDetails.map((complaint, index) => {
                                        return (
                                            <div className="row mt-4" key={index}>
                                                <div className="col-md-6 text-start">
                                                    <p className="fs-large-med">{complaint.title}</p>
                                                </div>
                                                <div className="col-md-6 text-end fs-large-med">
                                                    <p><span className="text-grey me-2">Qty</span><span>{complaint.qty}pcs</span></p>
                                                </div>
                                                <div className="col-md-12">
                                                    <label className="fs-normal-reg mb-2">Serial numbers</label>
                                                    <input
                                                        type="text"
                                                        className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"

                                                        disabled={true}
                                                        name="contact" value={complaint.serialNos}
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <label className="fs-normal-reg mb-2">Remarks</label>
                                                    <textarea
                                                        className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                                        disabled={true}
                                                        name="remarks" value={complaint.remarks}
                                                        rows={4}>{complaint.remarks}
                                                    </textarea>
                                                </div>
                                            </div>
                                        );
                                    }) : null}

                            </div>


                        </div>
                    </div>
                </div>
            </div>


        ) : (<ShimmerOrdersDetailTable />)
    )




}
export default ViewQuery