import React, { useState } from 'react'
import './login.scss'
import { useNavigate } from 'react-router'
import { adminLogin, login } from '../../../api/API'
import { useSnackbar } from 'react-simple-snackbar'
import backGround from '../../../assets/banner1.svg'
import { Link } from 'react-router-dom'
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { ADMIN, ADMIN_TYPE } from '../../../util/EnvHelper'
const Login = () => {

    const navigate = useNavigate()
    const [userName, setUserName] = useState(null)
    const [password, setPassword] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const [openSnackbar] = useSnackbar()

    const [state, setState] = useState({
        email: '', password: ''
    })
    const handleChange = (e, key) => {
        setState({
            ...state, [key]: e.target.value
        })
    }
    const makeLogin = () => {

        if (!state.email) {
            openSnackbar("User Name cannot be empty")
            return
        }

        if (!state.password) {
            openSnackbar("Password cannot be empty")
            return
        }

        setIsLoading(true)

        adminLogin({ username: state.email, password: state.password, userType: ADMIN_TYPE })
            .then(() => {
                setIsLoading(false)
                navigate('/dashboard')
            }).catch(e => {
                setIsLoading(false)
                openSnackbar(e)
            })

    }

    return (
        <>
            <img className="admin-banner" src={backGround} alt="banner" />
            <div className="container  mt-md-50">
                <div className="row justify-content-center">
                    <div className="col-md-4 p-2rem my-auto login-container col-11">
                        <h1 className="text-left  pb-4 fs-l-large-regular">Login</h1>
                        <input type="email" className="form-control login-input p-07rem mb-3 w-100 fs-x-large-regular px-4"
                            value={state.email}
                            onChange={(e) => { handleChange(e, 'email') }}
                            placeholder="Email id" />
                        <input type="password"
                            value={state.password}
                            className="form-control login-input p-07rem mb-3 w-100 fs-x-large-regular px-4"
                            onChange={(e) => { handleChange(e, 'password') }}
                            placeholder="Password" />
                        <div className="mb-2">
                            <FormControlLabel control={<Checkbox defaultChecked />}
                                onChange={() => { }} label={<p className="fs-normal-reg mb-0">Remember me</p>}
                            />
                        </div>
                        <button type="btn" onClick={() => { makeLogin() }}
                            className="btn bg-green btn-focus br-21 p-07rem mb-3 w-100 text-light fs-large-semibold">LOGIN</button>
                        {/* <div className="text-center my-1"><Link to="/admin-forgot-password" className="text-blue nonlink fs-large-regular">Forgot password?</Link></div> */}
                    </div></div></div>

        </>

    )
}

export default Login