const LSK_AUTH_TOKEN = 'auth_token'
const LSK_ADMIN_AUTH_TOKEN = 'admin_auth_token'

// const LSK_AUTH_REFRESH_TOKEN = 'auth_refresh_token'
const LSK_USER = 'user'
const LSK_USER_PARTNER = 'partner'
const IS_ALREADY_LOGGED = 'no'
const LSK_USER_NAME = 'user_name'
const LSK_ANNOUNCEMENT_FLAG = 'announcement_flag'

const ITEM_MIN_VAL = 4
const ACTIVE_STATE = 1


export const getAuthToken = () => {
    return localStorage.getItem(LSK_AUTH_TOKEN)
}

export const setAuthToken = (token) => {
    localStorage.setItem(LSK_AUTH_TOKEN, token)
}

export const getAdminAuthtoken = () => {
    return localStorage.getItem(LSK_ADMIN_AUTH_TOKEN)
}

export const setAdminAuthToken = (token) => {
    localStorage.setItem(LSK_ADMIN_AUTH_TOKEN, token)
}

export const removeAdminAuthToken = () => {
    localStorage.removeItem(LSK_ADMIN_AUTH_TOKEN)
}

export const setItem = (key, data) => {
    localStorage.setItem(key, data)
}

export const getItem = (key) => {
    return localStorage.getItem(key)
}

export const setUser = (data) => {
    localStorage.setItem(LSK_USER, JSON.stringify(data))
}

export const setUserPartner = (data) => {
    localStorage.setItem(LSK_USER_PARTNER, JSON.stringify(data))
}

export const getUser = () => {
    return JSON.parse(localStorage.getItem(LSK_USER))
}

export const getUserPartner = () => {
    return JSON.parse(localStorage.getItem(LSK_USER_PARTNER))
}

export const removeAuthToken = () => {
    localStorage.removeItem(LSK_AUTH_TOKEN)
}

export const removeUser = () => {
    localStorage.removeItem(LSK_USER)
}
export const setUserLogged = (data) => {
    localStorage.setItem(IS_ALREADY_LOGGED, data)
}

export const getUserLogged = () => {
    return JSON.parse(localStorage.getItem(IS_ALREADY_LOGGED))
}

export const setIncrement = (data) => {
    localStorage.setItem(ITEM_MIN_VAL, data)

}

export const removeIncrement = () => {
    localStorage.removeItem(ITEM_MIN_VAL)
}

export const getIncrement = () => {
    return localStorage.getItem(ITEM_MIN_VAL)

}

export const setActiveID = (value) => {
    localStorage.setItem(ACTIVE_STATE, value)

}

export const getActiveID = () => {
    return localStorage.getItem(ACTIVE_STATE)
}

export const setUserStatus = (value) => {
    localStorage.setItem(IS_ALREADY_LOGGED, value)

}

export const getUserStatus = () => {
    return localStorage.getItem(IS_ALREADY_LOGGED)
}

export const setUserName = (value) => {
    localStorage.setItem(LSK_USER_NAME, value)
}

export const getUserName = () => {
    return localStorage.getItem(LSK_USER_NAME)
}

export const setAnnoucementFlag = (value) => {
    localStorage.setItem(LSK_ANNOUNCEMENT_FLAG,JSON.stringify(value))
}

export const getAnnouncementStatus = () => {
    return JSON.parse(localStorage.getItem(LSK_ANNOUNCEMENT_FLAG))
}