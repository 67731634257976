import React from 'react'

const ReturnPolicy = () => {
  return (
    <>
    <div className='container'>
        <div className='my-md-100'>
        <p className='text-center'><strong  className='fs-title'>RETURN POLICY&nbsp;</strong></p>
<p><span  >Products can be returned within 2 calendar days of receipt.</span></p>
<p><span  >Eligibility Criteria for return&nbsp;</span></p>
<ol>
<li   aria-level="1"><span  >Item must be unused and in the same condition that you received it.&nbsp;</span></li>
<li   aria-level="1"><span  >Item must be in the original packaging.&nbsp;</span></li>
<li   aria-level="1"><span  >Should produce a Purchase receipt or proof of purchase (email invoice will also be considered valid)</span></li>
</ol>
<p><span  >Acceptance of all Returns are at the sole discretion of the JKPS upon verification done by the authorised JKPS employees.&nbsp;</span></p>
<p className='text-center'><strong >REFUND POLICY&nbsp;</strong></p>
<p><span  >On receipt of the returned product Winged Logistics Services would Inspect the product and verify the claims, and would intimate you on the receipt of product and status of the refund.&nbsp;</span></p>
<p><span  >The Refund would be made to the original method of payment within 30 working days from the date of Winged Logistics Services receives the returned product.</span></p>
<p><span  >Refunds and returns are deemed valid only if there&rsquo;s a problem with the product.&nbsp;</span></p>
<p><span  >For customers who purchased products from any retail stores/authorized distributors, return/refund shall be as per their terms of sale.</span></p>
<p className='text-center'><strong>SHIPPING POLICY</strong></p>
<p><span  >Winged Logistics Services cannot commit to any fixed time for delivery. The delivery of our products will be made between 9:00 am to 6:00 p.m. of the estimated delivery date. It may get delayed on certain days due to extreme weather conditions or governmental regulatory activities.</span></p>
<p><span  >The delivery will be made to the shipping address mentioned at the time of placing the order. The consignment will not be redirected/redelivered to any other address under any circumstances.</span></p>
<p><span  >No Deliveries will be made on such days as prohibited by the government.</span></p>
<p><span  >Winged Logistics Services delivery agent or partner agent will attempt delivery of the products only once. In case the delivery is not executed during the attempt, the customer shall still be charged for the order and no redelivery attempt will be made.</span></p>
<p><strong>Winged Logistics Services WILL CONSIDER THE ORDER AS &ldquo;EXECUTED&rdquo; IN THE FOLLOWING CASES:</strong></p>
<ul>
<li   aria-level="1"><span  >Delivery not done due to wrong address given by the customers</span></li>
<li   aria-level="1"><span  >Non-availability of the recipients at the address</span></li>
<li   aria-level="1"><span  >Premises locked.</span></li>
<li   aria-level="1"><span  >Wrong phone number or phone not working or switched off or not reachable, when tried by the delivery agent of Winged Logistics Services</span></li>
<li   aria-level="1"><span  >Addressee refused to accept the products.</span></li>
<li   aria-level="1"><span  >Delivered the product at the security cabin of the building/Reception/Neighbour, based on the instructions from the customer.</span></li>
<li   aria-level="1"><span  >In case of civil disturbances, floods, Heavy Rains, National Bandh or in happening any other force majeure event, we reserve the right to cancel the order or reschedule the delivery to another date.</span></li>
<li   aria-level="1"><span  >Requests for cancellation of confirmed order can be made up to 24 hours after the order placement time.</span></li>
</ul>
        </div>
    </div>
    </>
  )
}

export default ReturnPolicy