import React, { useEffect, useState } from 'react'
import SearchIcon from "@mui/icons-material/SearchOutlined";
import UserLayout from '../../../UserLayout';
import SearchMenu from '../../../components/search-dropdown/search-menu';
import { useSearchParams,useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { getProductList } from '../../../api/API';
import { CustomOptions } from '../login/Login';
import { useSnackbar } from 'react-simple-snackbar'
import { Link } from 'react-router-dom';


const SearchQuery = () => {
    
    const [searchParams] = useSearchParams()
    const params = Object.fromEntries([...searchParams]);
    const [searchQuery, setSearchQuery] = useState(null)
    const [isLoading, setIsLoading] = React.useState(true);
    const [productList, setProductList] = useState([]);
    const [openSnackbar] = useSnackbar(CustomOptions)
    const navigate = useNavigate();
    // var options;

    
    

    const getProdList = async () => {
        getProductList()
            .then(data => {
                setIsLoading(false)
                setProductList(data);
                // setOptions();

            }).catch(e => {
                setIsLoading(false)
                openSnackbar(e)
            })
    };

    const addProduct = (id) => {
        if(id !=="" && id !== null){
            // console.log(id)
            navigate('/search-query-result',{state: id})
        }
        else{
            openSnackbar("Please select an Item")
        }
        
    }

    useEffect(()=>{
        getProdList()
    },[])
    
    return (
        <UserLayout>
            <div className="container-fluid   align-self-center">
                <div className="row justify-content-center">

                    <div className="container my-md-200 ">
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-11 my-auto px-0">
                                <div className="col-md-12 col-11 my-auto">
                                    <div className="mb-3 d-lg-block d-md-block d-none">
                                        <div className="d-flex flex-row ">
                                            <Link className="d-flex flex-row  a-nonlink" to="/landing">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#5CA772" className="bi bi-chevron-left mt-1" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                                </svg>
                                                <p className="fs-large-med btn-link text-green ms-2">Home</p>
                                            </Link>
                                            <Link className="d-flex flex-row  a-nonlink" to="/query-list">
                                                <p className="fs-large-light text-dark ms-2">/ </p>
                                                <p className="fs-large-med btn-link text-green ms-2">Query List</p>
                                            </Link>
                                            <p className="fs-large-light text-dark ms-2">/ New Query</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-12 my-auto mt-2 search-query d-md-flex flex-md-row">

                                        <Select className='col-md-9 col-12 me-5 mb-3'
                                            options={productList.map(item => ({
                                                label: item.title, value: item.skuCode,
                                            }))}
                                            onChange = {opt => setSearchQuery(opt.value)}
                                        />
                                        <div className="col-md-4 col-4">
                                            <button onClick={() => {addProduct(searchQuery)}} className="btn w-100 btn-box  bg-green">
                                                <span className=" fs-normal-reg text-light" >Add</span>
                                            </button>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </UserLayout>
    )
}
export default SearchQuery