import React, { useEffect, useState } from "react";
import {getTrackDetails} from '../../../api/API'
import { useNavigate,useLocation } from 'react-router';
import { TRACK_URL } from "../../../util/EnvHelper";



const TrackModal = ({ trackingNo }) => {

    const [state, setState] = React.useState({
        gilad: true,
    });

    const navigate = useNavigate()
    const location = useLocation();

   const trackingDetails = (lrNo) => {
    //    getTrackDetails(lrNo)
    //    .then(data => {
    //        console.log(data)
    //    }).catch(e=>{
    //        console.log(e)
    //    })
        fetch(TRACK_URL+lrNo, {
            // headers: {
            //     'Access-Control-Allow-Origin':'http://wp.vrllogistics.com'
            // }    
        })
        .then(function(response) {
          console.log(response)
        }).catch(e => {
            console.log(e)
        })
        // .then(function(response) {
        //   setGifs({url:response.data.images.original.url})
        //   console.log(gifs.url)
        // });
      }

    useEffect(() => {
        window.onpopstate = e => {
            window.location.reload()
        }
        if(trackingNo!==null){
            trackingDetails(trackingNo)
        }
    },[trackingNo])

    return (
        <div className="modal fade custom-modal" id="trackModal" tabIndex="-1" aria-labelledby="TrackModal" aria-hidden="true">
            <div className="modal-dialog modal-lg max-w-600 modal-dialog-centered">
                <div className="modal-content">

                    <div className="modal-body px-0">
                        <div className="container  p-2rem">
                            <div className="row">

                                <div className="col-md-12 my-auto mb-2">
                                    <p className="float-start fs-large-med text-green"> Tracking Details </p>

                                    <button type="button" className=" float-end px-0 custom-btn bg-transparent border-0" data-bs-dismiss="modal" aria-label="Close">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-circle float-end text-primary" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </button>
                                </div>

                                 <p className="my-auto float-start fs-large-regular my-3">Tracking No: {trackingNo}</p>

                               {/* } <div className="row row-cols-2 my-2">
                                    {modelList!==null? modelList.map((item,index) => (
                                    
                                        <div className="col">
                                            <input type="checkbox" id={item.displayText} name={item.displayText} value={item.id} onClick={(e) => {handleModelList(e,item.id)}} className="bg-success" />
                                            <label for={item.displayText} className="fs-large-med ms-2">{item.displayText}</label>
                                        </div>
                                    
                                    )):null}
                                </div>

                                <hr></hr>

                                <p className="my-auto float-start fs-large-regular my-3">Color</p>

                                <div className="row row-cols-2 my-2">
                                    {colorList!==null? colorList.map((item,index) => (
                                        
                                            <div className="col">
                                                <input type="checkbox" id={item.displayText} name={item.displayText} value={item.id} onChange={(e) => {handleColorList(e,item.id)}} className="bg-success" />
                                                <label for={item.displayText} className="fs-large-med ms-2">{item.displayText}</label>
                                            </div>
                                        
                                    )):null}
                                </div>

                                <hr></hr>

                                <p className="my-auto float-start fs-large-regular my-3">Type</p>

                                <div className="row row-cols-2 my-2">
                                    {typeList!==null? typeList.map((item,index) => (
                                        <div className="col">
                                            <input type="checkbox" id={item.displayText} name={item.displayText} value={item.id} onClick={(e) => {handleTypeList(e,item.id)}} className="bg-success" />
                                            <label for={item.displayText} className="fs-large-med ms-2">{item.displayText}</label>
                                        </div>
                                    )):null}
                                </div> */}
                                
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer pb-0">
                        <button type="button" className="btn btn-secondary fs-large-med me-3" data-bs-dismiss="modal">Cancel</button>
                        <button className="btn btn-primary br-21 fs-large-semibold text-light px-5 py-2" data-bs-dismiss="modal">Ok</button>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default TrackModal