import SkuImage from "./SkuImages";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { AntSwitch, } from "../../../../scss/admin/mui";
import { uploadImage } from "../../../../api/API";
import { CustomOptions } from "../../../user/login/Login";
import { useSnackbar } from 'react-simple-snackbar';

const SkuFeature = ({ skuFeatureList, updateSkuList }) => {

    const [skuList, setSkuList] = useState([skuFeatureList])
    const [openSnackbar] = useSnackbar(CustomOptions)
    const [isUpload, setIsUpload] = React.useState(false);
    const [isFirstImage,setIsFirstIamge]= React.useState(0);
    useEffect(() => {
        setSkuList(skuFeatureList);
    }, [])

    const handleToggle = (statusVal, key, index) => {
        let status = (statusVal == 1 ? 0 : 1)

        setSkuList(s => {
            const newVariant = s.slice();
            newVariant[index][key] = status;
            updateSkuList(newVariant)
            return newVariant;
        });

    };
    const inutChange = (index, key, e) => {
        setSkuList(s => {
            const newVariant = s.slice();
            newVariant[index][key] = e.target.value;
            updateSkuList(newVariant)
            return newVariant;
        });

    };

    const priceChange = (parentIndex, index, key, e, pastGST) => {

        setSkuList(s => {
            let newGST = Number(e.target.value) + Number(pastGST)
            const newVariant = s.slice();
            newVariant[parentIndex].skuPriceList[index][key] = e.target.value;
            newVariant[parentIndex].skuPriceList[index]['price'] = newGST.toFixed(2);
            updateSkuList(newVariant)
            return newVariant;
        });

    };


    const addImage = (e, index, key) => {

        if (e.target.files && e.target.files[0]) {
            let numOfUpload = 0;
          
            setIsUpload(true)

            uploadImage(e.target.files[0], e.target.files[0].name)
                .then((data) => {
                    if (data) {
                        setSkuList(s => {
                          
                            const newVariantList = s.slice();

                            if (numOfUpload == 0) newVariantList[index][key].push(data)
                            
                            if (isFirstImage == 0) newVariantList[index].imageUrl = data
                           
                            numOfUpload = numOfUpload + 1
                            setIsFirstIamge(isFirstImage+1)
                            updateSkuList(newVariantList)
                            return newVariantList;

                        });

                        setIsUpload(false)
                    }
                }).catch(e => {

                    openSnackbar(e)
                    setIsUpload(false)

                })

        };
    }

    const selectPrice = (parentIndex, index, key, e) => {
        let status = (e.target.checked == true ? 1 : 0)
        setSkuList(s => {
            const newVariant = s.slice();
            newVariant[parentIndex].skuPriceList[index][key] = status;
            return newVariant;
        });
    }

    const addVariant = () => {
        setSkuList(s => [...s, {
            skuCode: '', skuName: '', imageList: [], color: '', skuPriceList: [{
                partCategoryDisplay: 'Dealer', basePrice: '', gst: '', price: '', status: 0
            }, {
                partCategoryDisplay: 'Distributor', basePrice: '', gst: '', price: '', status: 0
            }, {
                partCategoryDisplay: 'Chain', basePrice: '', gst: '', price: '', status: 0
            }], imageUrl: '', status: 0
        }]);
    };
    // console.log(skuList)
    return (
        <div>

            {skuList !== null ?
                skuList.map((item, index) => (
                    <>
                        <div className="container ms-3 inventory-accordion" key={index}>
                            <div className="row">
                                {/* <button id={"flush-headingOne" + index} className="accordion-button  collapsed  bg-transparent shadow-none " data-bs-toggle="collapse" data-bs-target={"#flush-collapseOne" + index}
                                        id="heading" aria-expanded="false" aria-controls={"flush-collapseOne" + 1}></button> */}
                                <div className="col-2 my-auto">

                                    <FormGroup className={"custom-switch-btn my-auto"}>
                                        <FormControlLabel
                                            control={<AntSwitch
                                                checked={item.status} name={'switch' + index} onChange={(e) => { handleToggle(item.status, 'status', index) }} />}
                                        />
                                    </FormGroup>

                                </div>

                                <div className="col-md-3 ">
                                    <label className="fs-normal-reg mb-2 ms-3">Sku Code</label>
                                    <input
                                        type="text"  data-rule="required"
                                        className="form-control login-input p-07rem  w-100 fs-large-med px-4  validate-me"
                                        onChange={(e) => inutChange(index, 'skuCode', e)}
                                        value={item.skuCode}
                                    />
                                           <p className="invalid-feedback fs-small-reg text-center"></p>

                                </div>
                                <div className="col-md-3 ms-4">
                                    <label className="fs-normal-reg mb-2  ms-3">Sku Name</label>
                                    <input
                                        type="text"  data-rule="required"
                                        className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4 validate-me"
                                        onChange={(e) => inutChange(index, 'skuName', e)}
                                        value={item.skuName}
                                    />
                                           <p className="invalid-feedback fs-small-reg text-center"></p>

                                </div>
                                {item.status ? <>
                                    <div className="col-md-3 my-auto">
                                        <button id={"flush-headingOne" + index} type="button" 
                                            className={"accordion-button   bg-transparent shadow-none collapsed"}
                                            data-bs-toggle="collapse" data-bs-target={"#flush-collapseOne" + index}
                                            aria-expanded={(item.status ? "true" : "false")} aria-controls={"flush-collapseOne" + index}></button>
                                    </div>
                                </> : null}



                            </div>

                          

                            {item.status ? <>
                                <div id={"flush-collapseOne" + index} className={"accordion-collapse " + (item.status ? "collapse show " : "collapse")}
                                    aria-labelledby={"flush-headingOne" + index} data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body px-0">
                                        <div className="row  custom-file-upload custom-radio-btn mt-2">
                                            <label className="fs-large-semibold mb-3 ">Images</label>

                                            <SkuImage
                                                imageListData={item}
                                                skuListData={skuList}
                                                indexVal={index} />

                                            <div className='file file--upload col-md-2'>
                                                <label for={'input-file' + index}>
                                                    {isUpload ? <div className="spinner-border text-success spinner-border-sm" role="status">
                                                    </div> :
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-plus-lg text-primary" viewBox="0 0 16 16">
                                                            <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                                        </svg>}
                                                </label>

                                                <input id={'input-file' + index} type='file'
                                                    onClick={(e) => { e.target.value = null }}
                                                    onChange={(e) => { addImage(e, index, 'imageList') }}
                                                />
                                            </div>
                                        </div>
                                        <hr className="hr01"></hr>
                                        <div className="custom-check-box2 mt-4">
                                            <label className="fs-large-semibold ">Prices</label>
                                            <div className="row">
                                                <div className="col-md-3"></div>
                                                                    <div className="col-md-3">
                                                                    <label className="fs-normal-reg mb-3 ms-3 ">Price Before GST</label>
                                                    </div>
                                                    <div className="col-md-3">
                                                    <label className="fs-normal-reg mb-3 ms-3">GST</label>
                                                    </div>
                                                    <div className="col-md-3">
                                                    <label className="fs-normal-reg mb-3 ms-3 ">Price After GST</label>
                                                                    </div>
                                                    </div>
                                            {item.skuPriceList.map((price, i) => {
                                                return (
                                                    <>
                                                        <FormGroup key={i}>
                                                            <div className="row">
                                                                <div className="col-md-3 ">
                                                                    <FormControlLabel className=" fs-x-large-med"
                                                                        control={<Checkbox checked={price.status} onChange={(e) => selectPrice(index, i, 'status', e)}
                                                                            name={price.partCategoryDisplay + index} />} label={price.partCategoryDisplay} />
                                                                    {/* <label className="fs-x-large-med mb-2">Dealer</label> */}

                                                                </div>
                                                                <div className="col-md-3 mb-3 ">
                                                                    
                                                                    <input
                                                                        type="number"   data-rule={price.status == 1?"required":""} 
                                                                        readOnly={price.status == 1 ? false : true}
                                                                        className="form-control login-input p-07rem  w-100 fs-large-med px-4 validate-me"
                                                                        onChange={(e) => priceChange(index, i, 'basePrice', e, price.gst)}
                                                                        value={price.status == 1 ? price.basePrice : ""}
                                                                    />
                                                                     <p className="invalid-feedback fs-small-reg text-center"></p>

                                                                </div>
                                                                <div className="col-md-3 mb-3 ">
                                                                 
                                                                    <input
                                                                        type="number"  data-rule={price.status == 1?"required":""} 
                                                                        readOnly={price.status == 1 ? false : true}
                                                                        className="form-control login-input p-07rem  w-100 fs-large-med px-4 validate-me"
                                                                        onChange={(e) => priceChange(index, i, 'gst', e, price.basePrice)}
                                                                        value={price.status == 1 ? price.gst : ""}
                                                                    />
                                                                 <p className="invalid-feedback fs-small-reg text-center"></p>

                                                                </div>
                                                                <div className="col-md-3 mb-3 ">
                                                                 
                                                                    <input
                                                                        type="number"
                                                                        readOnly
                                                                        className="form-control login-input p-07rem  w-100 fs-large-med px-4 "
                                                                        onChange={(e) => priceChange(index, i, 'price', e)}
                                                                        value={price.status == 1 ? price.price : ""}
                                                                    />
                                                               
 
                                                                </div>
                                                            </div>
                                                        </FormGroup>
                                                    </>)
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </> : null}

                        </div>
                        <hr></hr>
                    </>
                )) : null
            }


            <div className="ms-4 my-3">
                <button className="border-primary btn text-primary bg-transparent br-21 fs-large-semibold  px-5 py-2"
                  type="button"   onClick={addVariant} >+ Add new variant</button>

            </div>
        </div>
    )

}
export default SkuFeature