import React, { useEffect, useState } from 'react'
import './query.scss';
import UserLayout from '../../../UserLayout';
import {getQueryList} from '../../../api/API'
import { useSnackbar } from 'react-simple-snackbar'
import { CustomOptions } from '../login/Login';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {toDisplayDateTime} from '../../../util/Util';


const QueryList = () => {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(true);
    const [openSnackbar] = useSnackbar(CustomOptions)
    const [queryList, setQueryList] = useState([])

    const getQueries = async () => {
        getQueryList()
            .then(data => {
                setIsLoading(false)
                setQueryList(data);
            }).catch(e => {
                setIsLoading(false)
                openSnackbar(e)
            })
    };

    const viewComplaintDetails = (item) => {
        navigate("/query-details", { state: item })
    }

    useEffect( () => {
        getQueries()
    },[])

    return (
        <UserLayout>
            <div className="container-fluid   align-self-center">
                <div className="row justify-content-center">

                    <div className="container my-md-200">
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-11 px-0">

                                <div className="d-flex flex-row justify-content-between mb-4" >

                                    <div className="mb-3 d-lg-block d-md-block d-none">
                                        <div className="d-flex flex-row ">
                                            <Link className="d-flex flex-row  a-nonlink" to="/landing">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#5CA772" className="bi bi-chevron-left mt-1" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                                </svg>
                                                <p className="fs-large-med btn-link text-green ms-2">Home</p>
                                            </Link>
                                            <p className="fs-large-light text-dark ms-2">/ Query List</p>
                                        </div>
                                    </div>

                                    <Link to="/search-query">
                                        <button className="btn  btn-box">
                                            <span className="me-md-2 fs-large-regular text-green my-auto">+ </span>
                                            <span className="me-md-3 fs-normal-reg text-green my-auto" >New Query</span>
                                        </button>
                                    </Link>


                                </div>
                                {queryList!==null ? queryList.map((item,index) => (
                                    <>
                                        <div>
                                            <div className="d-flex flex-row justify-content-between" >
                                                <div className="text-dark">
                                                    <p className="fs-large-regular "><span>Complaint number: </span>
                                                        <span className="ms-1"><strong>{item.chRid}</strong></span></p>
                                                    <p className="fs-large-regular "><span>Complaint raised on: </span>
                                                        <span className="ms-1"><strong>{toDisplayDateTime(item.dateTime)}</strong></span></p>
                                                </div>
                                                <div className="my-auto">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" onClick={() => {viewComplaintDetails(item)}} className="bi bi-chevron-right pointer" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <hr></hr>
                                    </> 
                                    )) :null
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </UserLayout>
    )
}
export default QueryList