import React from "react";
import { Link } from "react-router-dom";
import TVSLOgo from '../../../assets/TVS_logo.svg';
import { useLocation } from "react-router-dom";
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import FileCpyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import ViewInArOutlinedIcon from '@mui/icons-material/ViewInArOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
const SideBar = () => {
    const location = useLocation();
    // console.log(location.pathname)
    return (

        <div id="sidebarContainer" className="h-100 col-md-2 col-3 sidebar-container bg-primary " style={{ position: 'fixed',overflowY:'scroll'}}>
            <div className="brand py-3 text-center">
                <Link to="/dashboard" className="a-nonlink"><img height='45px' src={TVSLOgo} alt="TVS Logo" />
                </Link>

            </div>
            <div className="sidenav-group mt-5 d-flex align-items-start">
                <div className="nav flex-column nav-pills ms-3 w-100" >
                    <Link to="/dashboard"
                        className={"nav-link sidenav-item fs-x-large-regular  text-center " + (location.pathname == "/dashboard" ? "active fs-x-large-semibold" : "fs-x-large-regular")}
                        type="button" role="tab" aria-selected="true">
                        <div className="d-flex flex-row h-100 my-auto ">
                            <DashboardCustomizeOutlinedIcon />
                            <p className="sidenav-item-text my-auto  ms-2">Dashboard</p>
                        </div>
                    </Link>

                    <Link to="/vendor" className={"nav-link sidenav-item fs-x-large-regular text-center " + (location.pathname == "/vendor" ? "active fs-x-large-semibold" : "fs-x-large-regular")}
                        type="button" role="tab"
                        aria-selected="false"> <div className="d-flex flex-row h-100 my-auto">
                            <StoreOutlinedIcon />
                            <span className="sidenav-item-text  ms-2">Partner</span>
                        </div>
                    </Link>

                    <Link to="/product-list" className={"nav-link sidenav-item fs-x-large-regular  text-center " + (location.pathname == "/product-list" ? "active fs-x-large-semibold" : "fs-x-large-regular")} type="button" role="tab"
                        aria-selected="false"> <div className="d-flex flex-row h-100 my-auto">
                            <ViewInArOutlinedIcon />
                            <span className="sidenav-item-text  ms-2">Product</span>
                        </div>
                    </Link>

                    <Link to="/order-list" className={"nav-link sidenav-item fs-x-large-regular  text-center " + (location.pathname == "/order-list" ? "active fs-x-large-semibold" : "fs-x-large-regular")} type="button"
                        role="tab" aria-selected="false">
                        <div className="d-flex flex-row h-100 my-auto  ">
                            <FileCpyOutlinedIcon />
                            <span className="sidenav-item-text  ms-2">Orders</span>
                        </div>
                    </Link>



                    <Link to="/user-query-list" className={"nav-link sidenav-item fs-x-large-regular  text-center " + (location.pathname == "/user-query-list" ? "active fs-x-large-semibold" : "fs-x-large-regular")} type="button"
                        role="tab" aria-selected="false">
                        <div className="d-flex flex-row h-100 my-auto  ">
                            <QueryStatsOutlinedIcon />
                            <span className="sidenav-item-text  ms-2">Query</span>
                        </div>
                    </Link>

                    <Link to="/feedback-list" className={"nav-link sidenav-item fs-x-large-regular  text-center " + (location.pathname == "/feedback-list" ? "active fs-x-large-semibold" : "fs-x-large-regular")} type="button"
                        role="tab" aria-selected="false">
                        <div className="d-flex flex-row h-100 my-auto  ">
                            <FeedbackOutlinedIcon />
                            <span className="sidenav-item-text  ms-2">Feedback</span>
                        </div>
                    </Link>

                    <Link to="/announcement" className={"nav-link sidenav-item fs-x-large-regular  text-center " + (location.pathname == "/announcement" ? "active fs-x-large-semibold" : "fs-x-large-regular")} type="button"
                        role="tab" aria-selected="false">
                        <div className="d-flex flex-row h-100 my-auto  ">
                            <CampaignOutlinedIcon />
                            <span className="sidenav-item-text  ms-2">Announcements</span>
                        </div>
                    </Link>

                    <Link to="/report-list" className={"nav-link sidenav-item fs-x-large-regular  text-center " + (location.pathname == "/report-list" ? "active fs-x-large-semibold" : "fs-x-large-regular")} type="button" role="tab"
                        aria-selected="false">
                        <div className="d-flex flex-row h-100 my-auto ">
                            <AssessmentOutlinedIcon />
                            <span className="sidenav-item-text   ms-2">Reports</span>
                        </div>
                    </Link>

                    <Link to="/sales-heirarchy" className={"nav-link sidenav-item fs-x-large-regular  text-center " + (location.pathname == "/sales-heirarchy" ? "active fs-x-large-semibold" : "fs-x-large-regular")} type="button" role="tab"
                        aria-selected="false">
                        <div className="d-flex flex-row h-100 my-auto ">
                            <AssessmentOutlinedIcon />
                            <span className="sidenav-item-text   ms-2">Sales Heirarchy</span>
                        </div>
                    </Link>
                </div>
            </div>
        </div>

    )
}
export default SideBar