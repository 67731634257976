import React, { useEffect, useState } from "react";
import AdminLayout from "../../../AdminLayout";
import {getPartnerList,getProductListdetails,getSalesReportDetails,getTaxInvoice,salesReportExport} from '../../../api/API'
import Select from 'react-select';
import Stack from '@mui/material/Stack';
import { PAGINATION_ITEM_PER_PAGE } from '../../../util/EnvHelper';
import Pagination from '@mui/material/Pagination';
import {toDisplayDateMonth, toPrefixedLocalePrice,calendarFormat} from '../../../util/Util'
import { ShimmerPartner } from '../../../components/shimmer-effect/ShimmerEffect';
import { CSVLink } from 'react-csv';



const Report = () => {
    const [partnerList,setPartnerList] = useState([])
    const [productList,setProductList] = useState([])
    const [state, setState] = React.useState({
        partenerList: [], noOfPages: 0, 
    });
    const [filterList, setFilterList] = useState([state.partenerList]);
    const itemsPerPage = PAGINATION_ITEM_PER_PAGE;
    const [page, setPage] = React.useState(1);
    const [isLoading, setIsLoading] = React.useState(true);
    let reportDate = new Date()
    let reportYear = reportDate.getFullYear()
    let reportMonth = (reportDate.getMonth()+1)
    const [date, setDate] = useState({
        fromDate: reportYear+'-'+reportMonth+'-01', toDate: '',
    })

    const [checked,setChecked] = useState(false)
    const [isClearable,setIsClearable] = useState(true)
    const fileName = "Sales-Report.xls";
    const headers = [
        { label: "Order No", key: "orderId" },
        { label: "Customer Name", key: "partName" },
        { label: "Customer Code", key: "partCode" },
        { label: "Order Date", key: "orderDate" },
        { label: "Order status", key: "statusText" },
        { label: "Total price", key: "netAmount" },
    ];

    const [reportParams,setReportParams] = useState({
        partRid:0, fromDate:date.fromDate, toDate:"", reportType:"html", includeProductAndQty:0
    })
    

    const onPageChange = (event, value) => {
        setPage(value);
    };

    useEffect(() => {
        getPartner()
      }, []);
    
    useEffect(() => {
        getSalesReport()
    },[reportParams])

      const getPartner = () => {
        getPartnerList()
            .then(data => {
                setPartnerList(data)
            })
    }

    const downloadInvoice = (ohRid) => {
        getTaxInvoice(ohRid)
        .then(res => {
            const filename = res.headers['content-disposition'].split('"')[1]
            console.log(filename);
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', filename);

            document.body.appendChild(link);
            link.click();
            })
    }

    const downloadReport = (type) => {
        salesReportExport({...reportParams,reportType:type})
        .then(res => {
            const filename = res.headers['content-disposition'].split('"')[1]
            console.log(filename);
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', filename);

            document.body.appendChild(link);
            link.click();
            })
    }
    
    const handleDate = (e) => {
        setDate({
            ...date,
            [e.target.name]: e.target.value,
        });
        setReportParams({
            ...reportParams,
            [e.target.name]:e.target.value
        })
    }

    const getSalesReport = () => { 
        getSalesReportDetails(reportParams)
        .then(data=>{
            setIsLoading(false)
            setState(
                {
                    ...state,
                    partenerList: data,
                    noOfPages: Math.ceil(data.length / itemsPerPage)

                })
                setFilterList(data)
        })    
    }

    const handleCheck = (e) => {
        if(!checked){
            setChecked(true)
            setReportParams({
                ...reportParams,
                includeProductAndQty:1
            })
            
        }
        else{
            setChecked(false)
            setReportParams({
                ...reportParams,
                includeProductAndQty:0
            })
            
        }
    }
    return (
        <AdminLayout title="Reports">
            <div className="container p-0">
                <div className="row p-0">
                    <div className="col-md-12 d-flex justify-content-between">
                        <div className="col-md-4 me-2">
                            <label className="fs-normal-reg mb-2 ">Partner</label>
                            <Select className='mb-3 fs-large-med'
                                aria-label="Default select example"
                                placeholder="Search"
                                isClearable={isClearable}
                                isSearchable={true}
                                options={partnerList?.map(item => ({
                                label: item.partName , value: item.partRid,
                                }))}
                                onChange = {opt => opt? setReportParams({
                                    ...reportParams,
                                    partRid: opt.value
                                }):setReportParams({
                                    ...reportParams,
                                    partRid:0
                                })}
                            />
                        </div>
                        <div className="col-md-5 d-flex">
                            <div className="mt-auto col-md-5">
                            <label className="fs-normal-reg ms-2 ">Date</label>
                                <input type="date" className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                    onChange={handleDate}
                                    name="fromDate" value={calendarFormat(date.fromDate)} />
                            </div>
                            <div className="col-1 d-flex justify-content-center align-items-center fs-large-reg mt-2">
                                To
                            </div>
                            <div className="mt-auto col-md-5">
                                <input type="date" className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                    onChange={handleDate}
                                    name="toDate" value={calendarFormat(date.toDate)} />
                            </div>
                        </div>
                        <div className="form-check d-flex align-items-center col-md-2 p-0">
                            <input className="form-check-input" type="checkbox" value={checked} id="includeProductAndQty" onChange={handleCheck}/>
                            <label className="form-check-label fs-normal-reg ms-1 mt-1" htmlFor="includeProductAndQty">
                                Include Product and Quantity
                            </label>
                        </div>
                        <button onClick={() => {downloadReport('excel')}} className="btn justify-content-center align-items-center d-flex p-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-file-earmark-excel text-primary" viewBox="0 0 16 16">
                                <path d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z" />
                                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                            </svg>
                        </button>
                        <button onClick={() => {downloadReport('pdf')}} className="btn justify-content-center text-danger align-items-center d-flex p-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"/>
                            </svg>
                        </button>
                    </div>
            
                    <div className=" col-md-12  p-3 table-container mt-3">
                        <table className="table table-striped table-borderless  ">
                            <thead>
                                <tr className="fs-large-light">
                                    <td scope="col" >
                                        <div className="d-flex flex-row my-auto">
                                            <div className="my-auto ">
                                                <p className="me-2 mb-0 ws-no-wrap">Order Id</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="col">
                                        <div className="d-flex flex-row my-auto">
                                            <div className="my-auto">
                                                <p className="me-2 mb-0 ws-no-wrap">Order Date</p>
                                            </div>
                                        </div>
                                    </td>
                                    {/* <td scope="col" >
                                        <div className=" d-flex flex-row my-auto">
                                            <div className="my-auto">
                                                <p className="me-2 mb-0">Location</p>
                                            </div>
                                            <div className="d-flex flex-column ">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-caret-up text-grey " viewBox="0 0 16 16" >
                                                    <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-caret-down text-grey" viewBox="0 0 16 16">
                                                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </td> */}
                                    <td scope="col" >
                                        <div className="d-flex flex-row my-auto">
                                            <div className="my-auto">
                                                <p className="me-2 mb-0">Partner Name</p>
                                            </div>
                                        </div>
                                    </td>
                                    {checked?
                                    <>
                                        <td scope="col" >
                                            <div className="d-flex flex-row my-auto">
                                                <div className="my-auto">
                                                    <p className="me-2 mb-0">Part No.</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td scope="col" >
                                            <div className="d-flex flex-row my-auto">
                                                <div className="my-auto">
                                                    <p className="me-2 mb-0">Quantity</p>
                                                </div>
                                            </div>
                                        </td>
                                    </>
                                    : null}
                                    <td scope="col" >
                                        <div className="d-flex flex-row my-auto">
                                            <div className="my-auto">
                                                <p className="me-2 mb-0">Amount</p>
                                            </div>
                                        </div>
                                    </td>
                                    
                                    <td scope="col" >
                                        <div className=" d-flex flex-row my-auto">
                                            <div className="my-auto">
                                                <p className="me-2 mb-0">Status</p>
                                            </div>
                                        </div>
                                    </td>
                                    {checked?
                                        null    :
                                        <td scope="col" >
                                            <div className=" d-flex flex-row my-auto">
                                                <div className="my-auto">
                                                    <p className="me-2 mb-0">Actions</p>
                                                </div>

                                            </div>
                                        </td>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? (<ShimmerPartner />) :
                                filterList !== null ?
                                filterList
                                            .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                                            .map((item, index) => {
                                                return (
                                                    
                                                        <tr key={filterList.indexOf(item)} className="align-middle fs-normal-semibold" >
                                                            <td scope="row">{item.orderId}</td>
                                                            <td className="text-start">{toDisplayDateMonth(item.orderDate)}</td>
                                                            <td>
                                                                {item.partName} ({item.partCode})
                                                            </td>
                                                            {checked?
                                                                <>
                                                                    <td>
                                                                        {item.skuCode}
                                                                    </td>
                                                                    <td>
                                                                        {item.qty}
                                                                    </td>
                                                                </>
                                                                :null
                                                            }
                                                            <td>
                                                                {toPrefixedLocalePrice(item.netAmount)}
                                                            </td>
                                                            <td>
                                                                {item.statusText}
                                                            </td>
                                                            <td>
                                                                {item.statusText === "Dispatched" && !checked? 
                                                                        <a onClick ={() => {downloadInvoice(item.ohRid)}} target="_blank" rel="noreferrer"  className="action-circle me-2 p-1 pointer">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                                                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                                                            </svg>
                                                                            
                                                                        </a>
                                                                        : null
                                                                }
                                                            </td>
                                                        </tr>

                                                
                                                );
                                            }) :
                                        <tbody> <td className=" w-3"></td>
                                            <td scope="row"></td>
                                            <td scope="row"><div className="w-100 mt-3 text-center justify-content-center">
                                                <h1 className="fs-large-regular"><i>No records</i></h1>
                                            </div></td></tbody>
                                }
                            </tbody>
                        </table>

                        <div className=" mt-5 justify-content-center text-center">
                            <Stack spacing={2} alignItems="center">
                                <Pagination
                                    count={Math.ceil((filterList).length / itemsPerPage)}
                                    page={page}
                                    onChange={onPageChange}
                                    defaultPage={1}
                                    size="large"
                                    variant="outlined" color="success"
                                    showFirstButton
                                    showLastButton
                                  />
                                  
                            </Stack>
                        </div>
                    </div>

                </div>

            </div>
        </AdminLayout >

    )
}
export default Report