import React, { createContext, useEffect, useState } from 'react'
import { getCartCount } from './api/API'
import { getAuthToken } from './util/LocalStorageUtil'

export const CartContext = createContext()
export const CartProvider = (props) => {


    const getCartCountData = () => {
        getCartCount()
            .then(response => {
                setCount(response.data.cartCount)
            }).catch(e => {
                console.log(e)
            })
    }

    useEffect(() => {
        if (getAuthToken()) //TODO : handle in App.js
            getCartCountData()
    }, [])

    const [count, setCount] = useState(null)
    return <CartContext.Provider
        value={
            {
                cartCount: count,
                getCount: getCartCountData
            }
        }
    >
        {props.children}
    </CartContext.Provider>
}