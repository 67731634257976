import React, { useEffect, useState, useContext } from 'react'
import './cart.scss';
import { Link } from 'react-router-dom';
import UserLayout from '../../../UserLayout';
import { ShimmerCart } from '../../../components/shimmer-effect/ShimmerEffect';
import { addToCart, getCartlist, removeCartItem, generateRazorPaymentId, displayRazorpay, getProductList, getProformaInvoice } from '../../../api/API'
import { useSnackbar } from 'react-simple-snackbar'
import { CustomOptions } from '../login/Login';
import { useNavigate } from 'react-router'
import { toPrefixedLocalePrice } from '../../../util/Util';
import { Modal } from 'bootstrap'
import DeleteModal from './DeleteModal'
import { getUser } from '../../../util/LocalStorageUtil'
import { CartContext } from '../../../CartContext';
import { BASE_URL } from "../../../util/EnvHelper"


const Cart = ({ emptyCart }) => {

    const [state, setState] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);
    const [isIncrement, setIsIncrement] = React.useState(false);
    const [isDecrement, setIsDecrement] = React.useState(false);
    const [loadedTarget, setLoadedTarget] = React.useState(null);
    const [cartlist, setCartlist] = useState([]);
    const [productList, setProductList] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [basePrice, setBasePrice] = useState(0);
    const [gstPrice, setGstPrice] = useState(0);
    const [openSnackbar] = useSnackbar(CustomOptions)
    const [oId, setOId] = useState(null);
    const [delID, setDelID] = useState(null);
    const [user, setUser] = useState([])
    const navigate = useNavigate();
    const { cartCount, getCount } = useContext(CartContext)
    const [invoice, setInvoice] = useState([])
    const [isPlaced, setIsPlaced] = useState(false)
    let validator = null

    const getProdList = async () => {
        getProductList()
            .then(data => {
                setIsLoading(false)
                setProductList(data);
                // console.log(data)
            }).catch(e => {
                setIsLoading(false)
                openSnackbar(e)
            })
    };

    const setValueIncrement = async (event, quantity, skuRid) => {
        // if (event.target.value < quantity) {
        setLoadedTarget(event.target.name)
        setIsIncrement(true)
        setState({ ...state, [event.target.name]: Number(event.target.value) + Number(user?.minQty) });
        let cartIncValue = Number(event.target.value) + Number(user?.minQty)
        addCart(cartIncValue, skuRid, true, event.target.name)
        // }
    };

    const setValueDecrement = async (event, skuRid) => {
        if (event.target.value > 4) {
            setLoadedTarget(event.target.name)
            setIsDecrement(true)
            setState({ ...state, [event.target.name]: Number(event.target.value) - Number(user?.minQty) });
            let cartDecValue = Number(event.target.value) - Number(user?.minQty);
            addCart(cartDecValue, skuRid, false, event.target.name)
        }
    };

    const addCart = (valueCount, skuID, isCheckIncrement, targetName) => {
        const result = productList.find(sku => (skuID === sku.skuRid))
        // console.log(valueCount)
        // console.log("coming")
        if (valueCount === 0) {
            openSnackbar('Please select quantity!')
            setLoadedTarget(targetName)
            setIsIncrement(false)
            return
        }
        else if (valueCount === Number(user?.minQty) && isCheckIncrement) {
            openSnackbar('Added to cart')
        }
        if (result.stock < valueCount && isCheckIncrement) {
            openSnackbar("Required quantity not in stock. Please try for lower quantity.")
            return
        }
        else {
            addToCart({ skuRid: skuID, qty: valueCount })
                .then((data) => {
                    if (isCheckIncrement === 1) {
                        setLoadedTarget(targetName)
                        setIsIncrement(false)
                    }
                    else {
                        setLoadedTarget(targetName)
                        setIsDecrement(false)
                    }
                    getCartDetails()


                })
                .catch(e => {
                    if (isCheckIncrement === 1) {
                        setLoadedTarget(targetName)
                        setIsIncrement(false)
                    }
                    else {
                        setLoadedTarget(targetName)
                        setIsDecrement(false)
                    }
                    openSnackbar(e)
                })
        }
    }

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script")
            script.src = src;
            script.onload = () => {
                resolve(true);
                setTimeout(enablePlace,5000)
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script)
        });
    };
const enablePlace = () => {
    setIsPlaced(false)
}
const forceRefresh = () => {
    window.location.reload()
}
    const orderPlaced = () => {
        setIsPlaced(true)
        orderValidation()
        const cartValidation = validator.find(element => element === false)
        // console.log(cartValidation)
         if(cartValidation !== false){
            loadScript("https://checkout.razorpay.com/v1/checkout.js");
            generateRazorPaymentId()
            .then((response) => {
                setOId(response)
                if (response.status === -1){
                    // const something = productList.map(item => {return item.skuRid})
                    // const result = productList.find(element => (element.skuRid === something))
                    // console.log(result)
                    // console.log(something)
                    // response.data.map(item => {
                    // openSnackbar("Items with skuRid: "+response.data.map(item => (item.skuRid))+" has only " +response.data.map(item => (item.availableQty))+" available quantity. Please update Cart")
                    // })
                    openSnackbar(response.description)
                    setTimeout(forceRefresh,3000)
                } 
                else{
                    displayRazorpay(response.orderId, response.amountInPaise)
                        .then((response) => {
                            navigate('/pay-history', { state: { ...response } })
                        }).catch(e => { 
                            // console.log(e)
                            // console.log("here")
                        })
                }

            })
        }
        else{
            openSnackbar("Please Update Cart")
        }
        
    };


    const getCartDetails = async () => {
        getCartlist()
            .then(data => {
                setCartlist(data);
                setIsLoading(false)
                const totalItemPrice = (items) => items.reduce((total, itemPrice) => total + (itemPrice.price * itemPrice.qty), 0);
                setTotalPrice(totalItemPrice(data))
                const totalBasePrice = (items) => items.reduce((total, itemPrice) => total + (itemPrice.basePrice * itemPrice.qty), 0);
                setBasePrice(totalBasePrice(data))
                const totalGstPrice = (items) => items.reduce((totalGst, itemPrice) => totalGst + ((itemPrice.price - itemPrice.basePrice) * itemPrice.qty), 0);
                setGstPrice(totalGstPrice(data))

            }).catch(e => {
                setIsLoading(false)
                openSnackbar(e)
            })
    };

    const removeFromCart = (del, skuRid) => {
        // console.log(del)
        // console.log(skuRid)
        if (del === "true") {
            removeCartItem({ skuRid: skuRid })
                .then(data => {
                    openSnackbar("Item Removed from Cart")
                    getCartDetails()
                    getCount()
                })
                .catch(e => {
                    openSnackbar(e)
                })
        }
        else {
            // console.log(false)
        }
    }

    const deleteModal = async (skuRid) => {
        const openDeleteModal = new Modal(document.querySelector('#deleteModal'), {
            keyboard: false
        })
        openDeleteModal.show();
        setDelID(skuRid)
        if (delID) {
            // console.log(delID)
        }
    }

    const handleFormData = (data) => {
        removeFromCart(data.delItem, delID)

    }

    const orderValidation = () => {
        validator = cartlist.map((item,index) => {
            if(item.qty > item.availableQty || item.availableQty === 0){
                return false
            }
            else{
                return true
            }
        })
    }
    const proformaInvoice = () => {
        orderValidation()
        const cartValidation = validator.find(element => element === false)
        if(cartValidation !== false)
        {
            getProformaInvoice()
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');

                link.href = url;
                link.setAttribute('download', 'Invoice.pdf');

                document.body.appendChild(link);
                link.click();
            })
        }
        else{
            openSnackbar("Please Update Cart")
        }
               
    }
    const CartVal = ({lqty,zqty,availableQty}) => {
        console.log("CartVal")
        if(lqty === true && zqty === true) {         
            return(
                <div className='col d-flex flex-row text-danger mt-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle me-2" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
                        </svg>
                        <p className='fs-large-med text-danger mb-0'>This item is currently out of stock. Please remove item from cart!</p>
                </div>
            )
        }
        else if(lqty === true && zqty === false){
            return(
                <div className='col d-flex flex-row text-danger mt-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle me-2" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
                    </svg>
                    <p className='fs-large-med text-danger mb-0'>Only {availableQty} qty in stock, Please lower item quantity to {availableQty}</p>
                </div>
            )
        }
        else{
            return null
        }

    }
    useEffect(() => {
        getCount()
        getProdList()
        getCartDetails()
        setUser(getUser())
    }, [cartCount])


    return (
        <UserLayout>
            <DeleteModal formData={handleFormData} />
            {isLoading ? (<ShimmerCart />) :
                <div className="container-fluid   align-self-center">
                    <div className="row justify-content-center">
                    </div>
                    <div className="container my-md-200 px-0 ">
                        <div className="row justify-content-center ">
                            <div className="col-md-8 d-lg-block d-md-block d-none">
                                <div className="mb-3">
                                    <div className="d-flex flex-row ">
                                        <Link className="d-flex flex-row  a-nonlink" to="/landing">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#5CA772" className="bi bi-chevron-left mt-1" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                            </svg>
                                            <p className="fs-large-med btn-link text-green ms-2">Home</p>
                                        </Link>
                                        <p className="fs-large-light text-dark ms-2">/ Your Cart</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8 ">
                                {cartlist.length !== 0 && cartlist !== null ?
                                    cartlist.map((item, index) => (
                                        
                                    <>
                                        {item.availableQty >= item.qty ?
                                            <>
                                                <div className="container px-0" key={index}>
                                                    <div className="row justify-content-between">

                                                        <div className="col-md-6 d-flex flex-row justify-content-between">  {/* NEW FLEX BY ROW FOR TITLE AND QTY BUTTONS */}
                                                            <h1 className="fs-large-semibold my-2">
                                                                {item.title}
                                                            </h1>

                                                            <div className="btn-none btn btn-box px-0 col-4 justify-content-between py-0 d-flex flex-row">
                                                                <button className="btn btn-success mx-0 my-0 py-2 px-2 px-lg-3 fs-x-large-med text-white" name={'btn' + index} value={item.qty}
                                                                    onClick={(e) => { setValueDecrement(e, item.skuRid) }}>-</button>
                                                                <p className=" mx-2 my-auto fs-normal-reg text-green" >{item.qty} pcs</p>
                                                                <button className="btn btn-success mx-0 my-0 py-2 px-2 px-lg-3 fs-x-large-med text-white" name={'btn' + index} value={item.qty}
                                                                    onClick={(e) => { setValueIncrement(e, item.qty, item.skuRid) }}>+</button>
                                                            </div>
                                                        </div>   {/* FLEX END */}

                                                        <div className="col-md-6 col-sm-8 d-flex flex-row justify-content-between">
                                                            <div className='col-md-3 col-sm-3 '>
                                                                <p className='fs-normal-reg my-3'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                        <path fill="#5CA772" d="M19 19H5V5H19M19 3H5A2 2 0 0 0 3 5V19A2 2 0 0 0 5 21H19A2 2 0 0 0 21 19V5A2 2 0 0 0 19 3M7 9H9.31L9.63 6H11.63L11.31 9H13.31L13.63 6H15.63L15.31 9H17V11H15.1L14.9 13H17V15H14.69L14.37 18H12.37L12.69 15H10.69L10.37 18H8.37L8.69 15H7V13H8.9L9.1 11H7V9M11.1 11L10.9 13H12.9L13.1 11Z" />
                                                                    </svg>
                                                                    {item.skuCode}
                                                                </p>
                                                            </div>
                                                            <div className='col-md-6 col-sm-8'>
                                                                <p className="fs-normal-reg my-3">{toPrefixedLocalePrice(item.basePrice * item.qty)}<span className='text-grey ps-2'>excl of GST</span></p>
                                                            </div>
                                                            <div onClick={() => { deleteModal(item.skuRid) }} className="col-md-2 col-sm-1 pointer">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash text-danger mx-4 my-3" viewBox="0 0 16 16">
                                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                                </svg>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <CartVal lqty={false} zqty={false} availableQty={item.availableQty} />
                                                <hr className="w-100"></hr>
                                            </> 
                                            :
                                            <>
                                                {item.availableQty !== 0 && item.availableQty < item.qty ?
                                                    <>
                                                        <div className="container px-0" key={index}>
                                                            <div className="row justify-content-between">

                                                                <div className="col-md-6 d-flex flex-row justify-content-between">  {/* NEW FLEX BY ROW FOR TITLE AND QTY BUTTONS */}
                                                                    <h1 className="fs-large-semibold my-2">
                                                                        {item.title}
                                                                    </h1>

                                                                    <div className="btn-none btn btn-box px-0 col-4 justify-content-between py-0 d-flex flex-row">
                                                                        <button className="btn btn-success mx-0 my-0 py-2 px-2 px-lg-3 fs-x-large-med text-white" name={'btn' + index} value={item.qty}
                                                                            onClick={(e) => { setValueDecrement(e, item.skuRid) }}>-</button>
                                                                        <p className=" mx-2 my-auto fs-normal-reg text-green" >{item.qty} pcs</p>
                                                                        <button className="btn btn-success mx-0 my-0 py-2 px-2 px-lg-3 fs-x-large-med text-white" name={'btn' + index} value={item.qty}
                                                                            onClick={(e) => { setValueIncrement(e, item.qty, item.skuRid) }}>+</button>
                                                                    </div>
                                                                </div>   {/* FLEX END */}

                                                                <div className="col-md-6 col-sm-8 d-flex flex-row justify-content-between">
                                                                    <div className='col-md-3 col-sm-3 '>
                                                                        <p className='fs-normal-reg my-3'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                <path fill="#5CA772" d="M19 19H5V5H19M19 3H5A2 2 0 0 0 3 5V19A2 2 0 0 0 5 21H19A2 2 0 0 0 21 19V5A2 2 0 0 0 19 3M7 9H9.31L9.63 6H11.63L11.31 9H13.31L13.63 6H15.63L15.31 9H17V11H15.1L14.9 13H17V15H14.69L14.37 18H12.37L12.69 15H10.69L10.37 18H8.37L8.69 15H7V13H8.9L9.1 11H7V9M11.1 11L10.9 13H12.9L13.1 11Z" />
                                                                            </svg>
                                                                            {item.skuCode}
                                                                        </p>
                                                                    </div>
                                                                    <div className='col-md-6 col-sm-8'>
                                                                        <p className="fs-normal-reg my-3">{toPrefixedLocalePrice(item.basePrice * item.qty)}<span className='text-grey ps-2'>excl of GST</span></p>
                                                                    </div>
                                                                    <div onClick={() => { deleteModal(item.skuRid) }} className="col-md-2 col-sm-1 pointer">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash text-danger mx-4 my-3" viewBox="0 0 16 16">
                                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                                        </svg>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <CartVal lqty={true} zqty={false} availableQty={item.availableQty} />
                                                        <hr className="w-100"></hr>
                                                    </>
                                                :
                                                    <>
                                                        <div className="container px-0" key={index}>
                                                            <div className="row justify-content-between">

                                                                <div className="col-md-6 d-flex flex-row justify-content-between">  {/* NEW FLEX BY ROW FOR TITLE AND QTY BUTTONS */}
                                                                    <h1 className="fs-large-semibold my-2">
                                                                        {item.title}
                                                                    </h1>

                                                                    <div className="btn-none btn btn-box px-0 col-4 justify-content-between py-0 d-flex flex-row">
                                                                        <button className="btn btn-success mx-0 my-0 py-2 px-2 px-lg-3 fs-x-large-med text-white" name={'btn' + index} value={item.qty}
                                                                            onClick={(e) => { setValueDecrement(e, item.skuRid) }}>-</button>
                                                                        <p className=" mx-2 my-auto fs-normal-reg text-green" >{item.qty} pcs</p>
                                                                        <button className="btn btn-success mx-0 my-0 py-2 px-2 px-lg-3 fs-x-large-med text-white" name={'btn' + index} value={item.qty}
                                                                            onClick={(e) => { setValueIncrement(e, item.qty, item.skuRid) }}>+</button>
                                                                    </div>
                                                                </div>   {/* FLEX END */}

                                                                <div className="col-md-6 col-sm-8 d-flex flex-row justify-content-between">
                                                                    <div className='col-md-3 col-sm-3 '>
                                                                        <p className='fs-normal-reg my-3'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                                                <path fill="#5CA772" d="M19 19H5V5H19M19 3H5A2 2 0 0 0 3 5V19A2 2 0 0 0 5 21H19A2 2 0 0 0 21 19V5A2 2 0 0 0 19 3M7 9H9.31L9.63 6H11.63L11.31 9H13.31L13.63 6H15.63L15.31 9H17V11H15.1L14.9 13H17V15H14.69L14.37 18H12.37L12.69 15H10.69L10.37 18H8.37L8.69 15H7V13H8.9L9.1 11H7V9M11.1 11L10.9 13H12.9L13.1 11Z" />
                                                                            </svg>
                                                                            {item.skuCode}
                                                                        </p>
                                                                    </div>
                                                                    <div className='col-md-6 col-sm-8'>
                                                                        <p className="fs-normal-reg my-3">{toPrefixedLocalePrice(item.basePrice * item.qty)}<span className='text-grey ps-2'>excl of GST</span></p>
                                                                    </div>
                                                                    <div onClick={() => { deleteModal(item.skuRid) }} className="col-md-2 col-sm-1 pointer">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash text-danger mx-4 my-3" viewBox="0 0 16 16">
                                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                                        </svg>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <CartVal lqty={true} zqty={true} availableQty={item.availableQty} />
                                                        <hr className="w-100"></hr>
                                                    </>
                                                }
                                            </>
                                        }
                                    </>

                                    )) :

                                    <div className="container px-0">
                                        <div className="row justify-content-center ">
                                            <div className="col-md-5 text-center">
                                                <h1 className="fs-x-large-med">Your cart is empty</h1>
                                            </div>
                                        </div>
                                    </div>}
                            </div>

                            <div className='d-block d-md-none d-lg-none m-5'></div>

                            {/* for desktop  design */}

                            {cartlist.length !== 0 && cartlist !== null ?

                                <div className="col-8 justify-content-center">
                                    <div className="col-md-6  mx-auto mt-5 mb-4 d-none d-md-block d-lg-block">
                                        <a onClick={() => proformaInvoice()} target="_blank" rel="noreferrer" className="btn w-100 btn-box btn-outline-success mx-auto mb-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                            </svg>
                                            <span className='fs-normal-reg text-green ps-3'>Proforma Invoice</span>
                                        </a>
                                        <div className='d-flex flex-row justify-content-between'>
                                            <p className="fs-normal-reg mb-2">Gross Amount:</p>
                                            <p className='fs-large-semibold'>{toPrefixedLocalePrice(basePrice)}</p>
                                        </div>
                                        <div className='d-flex flex-row justify-content-between'>
                                            <p className="fs-normal-reg mb-4">GST:</p>
                                            <p className='fs-large-semibold'>{toPrefixedLocalePrice(gstPrice)}</p>
                                        </div>
                                        <div className='d-flex flex-row justify-content-between'>
                                            <p className="fs-normal-reg">Total payable amount ({cartlist.length} items)</p>
                                            <p className="fs-x-large-semibold">{toPrefixedLocalePrice(totalPrice)}</p>
                                        </div>
                                        {/* <Link to="/payment" className="mt-md-5 mt-3" > */}
                                        <button disabled={isPlaced?true:false} onClick={orderPlaced} className="btn  btn-box w-100 bg-green">
                                            <span className="fs-normal-reg text-light">Place order</span>
                                        </button>
                                        {/* </Link> */}
                                    </div>
                                </div>
                                : <div className="col-md-2  d-none d-md-block d-lg-block  my-auto">

                                    <Link to="/landing">
                                        <button className="btn  btn-box w-100 bg-green">

                                            <p className=" fs-normal-reg text-light my-1" >Continue shopping</p>

                                        </button>
                                    </Link>

                                </div>}
                        </div>
                    </div>



                    {/* for mobile design */}


                    {cartlist.length !== 0 && cartlist !== null ?

                        <div className="d-sm-block d-md-none d-lg-none">
                            <div className=" d-flex flex-row  py-2 w-100 fixed-bottom sticky-div">
                                <div className="col-10 mx-3 text-left">

                                    <a onClick={() => proformaInvoice()} target="_blank" rel="noreferrer"  className="btn w-100 btn-box btn-outline-success mx-auto mb-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                        </svg>
                                        <span className='fs-normal-reg text-green ps-3'>Proforma Invoice</span>
                                    </a>
                                    <div className='d-flex flex-row justify-content-between'>
                                        <p className="fs-normal-reg mb-2">Gross Amount:</p>
                                        <p className='fs-large-semibold'>{toPrefixedLocalePrice(basePrice)}</p>
                                    </div>
                                    <div className='d-flex flex-row justify-content-between'>
                                        <p className="fs-normal-reg mb-4">GST:</p>
                                        <p className='fs-large-semibold'>{toPrefixedLocalePrice(gstPrice)}</p>
                                    </div>
                                    <div className='d-flex flex-row justify-content-between'>
                                        <p className="fs-normal-reg">Total payable amount ({cartlist.length} items)</p>
                                        <p className="fs-x-large-semibold">{toPrefixedLocalePrice(totalPrice)}</p>
                                    </div>
                                    {/* <Link to="/payment" className="mt-md-5 mt-3" > */}
                                    <button  disabled={isPlaced?true:false}  onClick={orderPlaced} className="btn  btn-box w-100 bg-green">
                                        <span className="fs-normal-reg text-light">Place order</span>
                                    </button>
                                    {/* </a>*/}

                                </div>
                            </div>
                        </div>

                        :
                        <div className="d-sm-block d-md-none d-lg-none">
                            <div className="row justify-content-center py-2 w-100 sticky-div" >
                                <div className="col-10  text-center">

                                    <Link to="/landing">
                                        <button className="btn  btn-box w-100 bg-green">
                                            <span className=" fs-normal-reg text-light" >Continue shopping</span>
                                        </button>
                                    </Link>

                                </div>
                            </div>
                        </div>}
                </div>}
        </UserLayout >
    )
}
export default Cart